@charset "UTF-8";
.site-container {
  max-width: 1200px;
  margin: 0 auto;
}

.site-navbar .navbar-header .navbar-brand-logo {
  height: 24px;
}

.site-navbar .navbar-header .navbar-brand-logo-normal {
  display: none;
}

.site-navbar .navbar-header .navbar-brand {
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

@media (min-width: 768px) {
  .site-navbar .navbar-header {
    width: 160px;
  }
}

.site-navbar .navbar-container {
  background-color: transparent;
}

@media (min-width: 768px) {
  .site-navbar .navbar-container {
    margin-left: 160px;
  }
  .site-navbar .navbar-container #toggleMenubar {
    display: none;
  }
}

@media (max-width: 767px) {
  .site-navbar .navbar-container {
    background-color: #ffffff;
  }
}

.site-navbar.navbar-inverse .navbar-header .hamburger:before, .site-navbar.navbar-inverse .navbar-header .hamburger:after,
.site-navbar.navbar-inverse .navbar-header .hamburger .hamburger-bar {
  background-color: #ffffff;
}

.site-navbar.navbar-inverse .navbar-header .navbar-brand-logo-normal {
  display: inline-block;
}

.site-navbar.navbar-inverse .navbar-header .navbar-brand-logo-special {
  display: none;
}

.site-navbar.navbar-inverse .navbar-container {
  background-color: transparent;
}

@media (max-width: 767px) {
  .site-navbar.navbar-inverse .navbar-container {
    background-color: #ffffff;
  }
}

@media (max-width: 767px) {
  .site-navbar.navbar-inverse .navbar-toolbar > li > a {
    color: inherit;
  }
}

@media (min-width: 1600px) {
  .site-navbar {
    padding-left: calc((100% - 1600px) / 2 + 10px) !important;
    padding-right: calc((100% - 1600px) / 2 + 30px) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .site-navbar {
    padding-left: calc((100% - 1200px) / 2 + 10px) !important;
    padding-right: calc((100% - 1200px) / 2 + 30px) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .site-navbar {
    padding-left: calc((100% - 992px) / 2 + 10px) !important;
    padding-right: calc((100% - 992px) / 2 + 30px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .site-navbar {
    padding-left: calc((100% - 768px) / 2 + 10px) !important;
    padding-right: calc((100% - 768px) / 2 + 30px) !important;
  }
}

#site-navbar-search {
  z-index: 1;
}

html, body {
  height: 100%;
}

body {
  padding-top: 66.01px;
}

.site-menubar {
  z-index: 1400;
  position: fixed;
  top: 66.01px;
  background: #ffffff;
  color: rgba(97, 97, 97, 0.9);
  height: 5rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.site-menubar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-menubar a {
  outline: none;
}

.site-menubar a:hover, .site-menubar a:focus {
  text-decoration: none;
}

.site-menubar.site-menubar-dark {
  background: #212121;
  color: rgba(158, 158, 158, 0.9);
}

@media (max-width: 767px) {
  .page {
    padding-top: 0;
  }
  .site-menubar {
    width: 260px;
  }
  .site-menubar-body {
    height: 100%;
  }
}

@media (min-width: 1600px) {
  .site-menubar {
    padding-left: calc((100% - 1600px) / 2 + 30px) !important;
    padding-right: calc((100% - 1600px) / 2 + 30px) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .site-menubar {
    padding-left: calc((100% - 1200px) / 2 + 30px) !important;
    padding-right: calc((100% - 1200px) / 2 + 30px) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .site-menubar {
    padding-left: calc((100% - 992px) / 2 + 30px) !important;
    padding-right: calc((100% - 992px) / 2 + 30px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .site-menubar {
    padding-left: calc((100% - 768px) / 2 + 30px) !important;
    padding-right: calc((100% - 768px) / 2 + 30px) !important;
  }
}

.site-menu {
  font-size: 0;
}

.site-menu-category {
  display: none;
}

.site-menu-icon {
  display: inline-block;
  margin-right: 4px;
  width: 1em;
  text-align: center;
}

.site-menu-title {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  text-align: center;
}

.site-menu-arrow {
  font-family: "Material Design Iconic";
  transition: transform 0.15s;
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  right: 20px;
}

.site-menu-arrow:before {
  content: "";
}

.site-menu-item {
  font-size: 14px;
}

.site-menu-item > a {
  display: block;
  padding: 0 30px 0 20px;
  line-height: 38px;
}

.site-menu-item.has-sub {
  position: relative;
}

.site-menu-item:hover > .site-menu-sub {
  display: block;
}

.site-menu > .site-menu-item {
  display: inline-block;
}

.site-menu > .site-menu-item > a {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  padding: 13px 20px;
}

.site-menu > .site-menu-item > a > .site-menu-icon {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 20px;
  margin: 2px 0 4px;
}

.site-menu > .site-menu-item > a > .site-menu-title {
  display: block;
  line-height: 20px;
}

.site-menu > .site-menu-item > a > .site-menu-arrow {
  display: none;
}

.site-menu-sub {
  display: none;
  background-color: #ffffff;
  border: 1px solid rgba(40, 53, 147, 0.04);
  position: absolute;
  left: 0;
  top: 100%;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
}

.site-menu-sub .site-menu-item > a {
  width: 200px;
}

.site-menu-sub .site-menu-sub {
  left: 100%;
  top: 0;
}

.site-menu-item > a {
  color: inherit;
}

.site-menu-item > a:hover {
  color: rgba(97, 97, 97, 0.9);
  background-color: rgba(40, 53, 147, 0.04);
}

.site-menu-item:hover > a {
  color: rgba(97, 97, 97, 0.9);
  background-color: rgba(40, 53, 147, 0.04);
}

.site-menu-item.active > a {
  color: rgba(40, 53, 147, 0.9);
  background-color: rgba(40, 53, 147, 0.04);
}

ul.site-menu-sub {
  padding: 5px 0;
}

.site-menubar-dark .site-menu-item > a {
  color: inherit;
}

.site-menubar-dark .site-menu-item > a:hover {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.02);
}

.site-menubar-dark .site-menu-item:hover > a {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.02);
}

.site-menubar-dark .site-menu-item.active > a {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.06);
}

.site-menubar-dark .site-menu-sub {
  background-color: #1e1e1e;
}

@media (max-width: 767px) {
  .site-menu-category {
    display: block;
    font-size: 15px;
    padding: 0 24px;
    line-height: 46px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .site-menu-title {
    max-width: 160px;
  }
  .site-menu-title:first-child {
    margin-left: 28px;
  }
  .site-menu-item > a {
    padding: 0 30px;
    line-height: 38px;
  }
  .site-menu-item.has-sub {
    position: relative;
  }
  .site-menu-item:hover > .site-menu-sub {
    display: none;
  }
  .site-menu-item.open > a .site-menu-arrow {
    transform: rotate(90deg);
  }
  .site-menu-item.open > .site-menu-sub {
    display: block;
  }
  .site-menu > .site-menu-item {
    display: block;
  }
  .site-menu > .site-menu-item > a {
    line-height: 46px;
    padding: 0 30px;
  }
  .site-menu > .site-menu-item > a > .site-menu-icon {
    display: inline-block;
    width: auto;
    line-height: inherit;
    font-size: 14px;
    margin-right: 14px;
    margin-bottom: 0;
  }
  .site-menu > .site-menu-item > a > .site-menu-title {
    display: inline-block;
    line-height: inherit;
  }
  .site-menu > .site-menu-item > a > .site-menu-arrow {
    display: inline-block;
  }
  .site-menu-sub {
    display: none;
    background-color: transparent;
    border: none;
    position: relative;
    top: 0;
    box-shadow: none;
  }
  .site-menu-sub .site-menu-item > a {
    width: auto;
  }
  .site-menu-sub .site-menu-sub {
    left: 0;
    top: 0;
  }
  .site-menu-item > a {
    color: inherit;
  }
  .site-menu-item > a:hover {
    color: rgba(97, 97, 97, 0.9);
    background-color: rgba(40, 53, 147, 0.04);
  }
  .site-menu-item.open {
    background-color: rgba(40, 53, 147, 0.04);
  }
  .site-menu-item.open > a {
    color: rgba(40, 53, 147, 0.9);
    background-color: transparent;
  }
  .site-menu-item.open > a:hover {
    background-color: transparent;
  }
  .site-menu-item.active > a {
    color: rgba(40, 53, 147, 0.9);
    background-color: rgba(40, 53, 147, 0.04);
  }
  .site-menu-item.active.open > a {
    background-color: transparent;
  }
  ul.site-menu-sub {
    padding: 0;
  }
  .site-menubar-dark .site-menu-sub {
    background-color: transparent;
  }
  .site-menubar-dark .site-menu-item > a {
    color: inherit;
  }
  .site-menubar-dark .site-menu-item > a:hover {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.02);
  }
  .site-menubar-dark .site-menu-item.open {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .site-menubar-dark .site-menu-item.open > a {
    color: #ffffff;
    background-color: transparent;
  }
  .site-menubar-dark .site-menu-item.open > a:hover {
    background-color: transparent;
  }
  .site-menubar-dark .site-menu-item.active > a {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.06);
  }
  .site-menubar-dark .site-menu-item.active.open > a {
    background-color: transparent;
  }
}

.site-sidebar {
  z-index: 1210;
}

.site-sidebar.slidePanel {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.site-sidebar.slidePanel.slidePanel-right, .site-sidebar.slidePanel.slidePanel-left {
  width: 300px;
  overflow: hidden;
}

.site-sidebar-content {
  height: 100%;
}

.site-sidebar-nav.nav-tabs > .nav-item {
  flex: 1 1 33.3333%;
  margin-left: 0px;
}

.site-sidebar-nav.nav-tabs > .nav-item > .nav-link {
  font-size: 16px;
  line-height: 90px;
  text-align: center;
  padding: 0;
}

.site-sidebar-nav.nav-tabs > .nav-item > .nav-link > .icon {
  margin-right: 0;
}

.site-sidebar-tab-content {
  height: 100%;
  height: -webkit-calc(100% - 90px);
  height: calc(100% - 90px);
}

.site-sidebar-tab-content > .tab-pane {
  height: 100%;
  padding: 20px 30px;
}

.site-sidebar-tab-content > .tab-pane.scrollable {
  padding: 0;
}

.site-sidebar-tab-content > .tab-pane .scrollable-content {
  padding: 20px 30px;
}

.site-sidebar-tab-content > .tab-pane .list-group {
  margin-left: -30px;
  margin-right: -30px;
}

.site-sidebar-tab-content > .tab-pane .list-group > .list-group-item {
  padding-left: 30px;
  padding-right: 30px;
}

.site-sidebar .conversation {
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 1700;
  transition: all .3s;
}

.site-sidebar .conversation.active {
  right: 0;
}

.site-sidebar .conversation-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.site-sidebar .conversation-header > * {
  margin: 0;
  padding: 0 30px;
  line-height: 90px;
}

.site-sidebar .conversation-return {
  position: relative;
  z-index: 1;
  cursor: pointer;
  color: rgba(66, 66, 66, 0.4);
}

.site-sidebar .conversation-return:hover, .site-sidebar .conversation-return:focus {
  color: rgba(66, 66, 66, 0.6);
}

.site-sidebar .conversation-return:active {
  color: #424242;
}

.site-sidebar .conversation-title {
  position: relative;
  top: 1px;
  color: #424242;
  text-align: center;
  z-index: -1;
}

.site-sidebar .conversation-content {
  padding: 30px 15px;
}

.site-sidebar .conversation-reply {
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}

.site-sidebar .conversation-reply .form-control {
  border: none;
  border-right: 1px solid #e0e0e0;
}

.site-sidebar .conversation .chats {
  position: relative;
  top: 90px;
}

@media (max-width: 767px) {
  .site-sidebar.slidePanel.slidePanel-right, .site-sidebar.slidePanel.slidePanel-left {
    width: 100%;
  }
}

.site-action {
  position: fixed;
  right: 32px;
  bottom: 55px;
  z-index: 1290;
  animation-duration: 3s;
}

.site-action input {
  display: none;
}

.site-action .btn {
  box-shadow: 0 10px 10px 0 rgba(60, 60, 60, 0.1);
}

.site-action .front-icon {
  display: block;
}

.site-action .back-icon {
  display: none;
}

.site-action-buttons {
  position: absolute;
  bottom: 56px;
  left: 0;
  display: none;
  width: 100%;
  text-align: center;
}

.site-action-buttons .btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  animation-delay: 100ms;
}

.site-action.active .front-icon {
  display: none;
}

.site-action.active .back-icon {
  display: block;
}

.site-action.active .site-action-buttons {
  display: block;
}

@media (max-width: 767px) {
  .site-action .btn-floating {
    width: 46px;
    height: 46px;
    padding: 0;
    font-size: 16px;
    box-shadow: 0 6px 6px 0 rgba(60, 60, 60, 0.1);
  }
  .site-action-buttons {
    bottom: 46px;
  }
}

body {
  background-color: #f1f4f5;
}

.page {
  margin: 0 auto;
  background: #f1f4f5;
  position: relative;
  min-height: calc(100% - 44px);
}

.page-dark.layout-full {
  color: #ffffff;
}

.page-dark.layout-full:before {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  z-index: -1;
}

.page-dark.layout-full:after {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #212121;
  z-index: -1;
}

.page-dark.layout-full .brand {
  margin-bottom: 22px;
}

.page-dark.layout-full .brand-text {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.page-nav-tabs {
  padding: 0 30px;
}

.page-content {
  padding: 30px 30px;
}

@media (max-width: 479px) {
  .page-content {
    padding: 10px;
  }
}

.page-content-actions {
  padding: 0 30px 30px;
}

.page-content-actions .dropdown {
  display: inline-block;
}

.page-content-actions::after {
  display: block;
  clear: both;
  content: "";
}

.page-content-table {
  padding: 0;
  max-width: 100%;
  overflow-x: auto;
}

.page-content-table .table > thead > tr > th,
.page-content-table .table > tbody > tr > th,
.page-content-table .table > thead > tr > td,
.page-content-table .table > tbody > tr > td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-content-table .table > thead > tr > th:first-child,
.page-content-table .table > tbody > tr > th:first-child,
.page-content-table .table > thead > tr > td:first-child,
.page-content-table .table > tbody > tr > td:first-child {
  padding-left: 30px;
}

.page-content-table .table > thead > tr > th:last-child,
.page-content-table .table > tbody > tr > th:last-child,
.page-content-table .table > thead > tr > td:last-child,
.page-content-table .table > tbody > tr > td:last-child {
  padding-right: 30px;
}

.page-content-table .table > tbody > tr:hover > td {
  background-color: #eeeeee;
}

.page-content-table .table > tbody > tr > td {
  cursor: pointer;
}

.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}

.page-content-table .table.is-indent > thead > tr > th.pre-cell, .page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  padding: 0;
  border-top: none;
  border-bottom: none;
  width: 30px;
}

.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}

.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell, .page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}

.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}

.page-content-table .pagination {
  margin-left: 30px;
  margin-right: 30px;
}

.page-copyright {
  margin-top: 60px;
  color: #424242;
  font-size: 0.858rem;
  letter-spacing: 1px;
}

.page-copyright .social a {
  margin: 0 10px;
  text-decoration: none;
}

.page-copyright .social .icon {
  font-size: 1.143rem;
  color: rgba(66, 66, 66, 0.6);
}

.page-copyright .social .icon:hover, .page-copyright .social .icon:focus {
  color: rgba(66, 66, 66, 0.8);
}

.page-copyright .social .icon.active, .page-copyright .social .icon:active {
  color: #424242;
}

.page-copyright-inverse {
  color: #ffffff;
}

.page-copyright-inverse .social .icon {
  color: #ffffff;
}

.page-copyright-inverse .social .icon:hover, .page-copyright-inverse .social .icon:active {
  color: white;
}

@media (min-width: 1600px) {
  .page {
    max-width: 1600px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .page {
    max-width: 1200px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page {
    max-width: 992px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page {
    max-width: 768px;
  }
}

.page-header + .page-content {
  padding-top: 0;
}

.page-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 26px;
}

.page-title > .icon {
  margin-right: .3em;
}

.page-description {
  color: #9e9e9e;
}

.page-header {
  position: relative;
  padding: 30px 30px;
  margin-top: 0;
  margin-bottom: 0;
  background: transparent;
  border-bottom: 0;
}

.page-header-actions {
  position: absolute;
  top: 50%;
  right: 30px;
  margin: auto;
  transform: translateY(-50%);
}

.page-header-actions .btn-icon {
  margin-left: 6px;
}

.page-header-actions > * {
  margin-bottom: 0;
}

.page-header .breadcrumb {
  padding: 0;
  margin: 0;
}

.page-header-bordered {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  background-color: #ffffff;
  border-bottom: 1px solid transparent;
}

.page-header-tabs {
  padding-bottom: 0;
}

.page-header-tabs .nav-tabs-line {
  margin-top: 5px;
  border-bottom-color: transparent;
}

.page-header-tabs .nav-tabs-line > li > a {
  padding: 5px 20px;
}

.page-aside {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 260px;
  border-right: 1px solid #e0e0e0;
  background: #ffffff;
  transition: visibility .1s ease,top .3s ease,left .5s ease, right .5s ease;
  overflow-y: hidden;
}

.page-aside .list-group-item.active, .page-aside .list-group-item.active:focus, .page-aside .list-group-item.active:hover {
  z-index: 0;
}

.page-aside-left .page-aside {
  left: 0;
}

.page-aside-left .page-aside + .page-main {
  margin-left: 260px;
}

.page-aside-right .page-aside {
  right: 0;
}

.page-aside-right .page-aside + .page-main {
  margin-right: 260px;
}

.page-aside-right .page-aside .page-aside-inner {
  border-left: 1px solid #e0e0e0;
  border-right: none;
}

.page-aside-fixed .page-aside {
  position: fixed;
  top: 66.01px;
  height: calc(100% - 66.01px);
}

.page-aside-fixed .page-aside-inner {
  overflow-y: scroll;
  height: 100%;
}

.page-aside-fixed.page-aside-left .site-footer {
  margin-left: 260px;
}

.page-aside-fixed.page-aside-right .site-footer {
  margin-right: 260px;
}

.page-aside-section {
  position: relative;
}

.page-aside-section:first-child {
  padding-top: 22px;
}

.page-aside-section:last-child {
  margin-bottom: 22px;
}

.page-aside-section:after {
  content: '';
  position: relative;
  display: block;
  margin: 22px;
  border-bottom: 1px solid #e0e0e0;
}

.page-aside-section:last-child:after {
  display: none;
}

.page-aside-switch {
  display: none;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 25px);
  background-color: white;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;
}

.page-aside-switch .md-chevron-right {
  display: inline-block;
}

.page-aside-switch .md-chevron-left {
  display: none;
}

.page-aside-left .page-aside-switch {
  left: calc(100% - 1px);
  padding: 15px 8px 15px 4px;
  border-radius: 0 100px 100px 0;
}

.page-aside-left .page-aside-switch .md-chevron-right {
  display: inline-block;
}

.page-aside-left .page-aside-switch .md-chevron-left {
  display: none;
}

.page-aside-right .page-aside-switch {
  left: auto;
  right: calc(100% - 1px);
  padding: 15px 4px 15px 8px;
  border-radius: 100px 0 0 100px;
}

.page-aside-right .page-aside-switch .md-chevron-right {
  display: none;
}

.page-aside-right .page-aside-switch .md-chevron-left {
  display: inline-block;
}

.page-aside-title {
  padding: 10px 30px;
  margin: 20px 0 10px;
  font-weight: 500;
  color: #616161;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}

.page-aside .list-group {
  margin-bottom: 22px;
}

.page-aside .list-group-item {
  padding: 13px 30px;
  margin-bottom: 1px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-aside .list-group-item .icon {
  color: #9e9e9e;
}

.page-aside .list-group-item .list-content {
  width: 100%;
}

.page-aside .list-group-item .item-right {
  float: right;
}

.page-aside .list-group-item:hover, .page-aside .list-group-item:focus {
  background-color: #eeeeee;
  border: none;
  color: #3f51b5;
}

.page-aside .list-group-item:hover > .icon, .page-aside .list-group-item:focus > .icon {
  color: #3f51b5;
}

.page-aside .list-group-item.active {
  background-color: transparent;
  color: #3f51b5;
}

.page-aside .list-group-item.active > .icon {
  color: #3f51b5;
}

.page-aside .list-group-item.active:hover, .page-aside .list-group-item.active:focus {
  background-color: #eeeeee;
  border: none;
  color: #3f51b5;
}

.page-aside .list-group-item.active:hover > .icon, .page-aside .list-group-item.active:focus > .icon {
  color: #3f51b5;
}

.page-aside .list-group.has-actions .list-group-item {
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 2.573rem;
}

.page-aside .list-group.has-actions .list-group-item .list-editable {
  display: none;
  position: relative;
}

.page-aside .list-group.has-actions .list-group-item .list-editable .input-editable-close {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  outline: none;
  -webkit-appearance: none;
}

.page-aside .list-group.has-actions .list-group-item .list-editable .form-group {
  margin: 0;
}

.page-aside .list-group.has-actions .list-group-item .item-actions {
  display: none;
  position: absolute;
  right: 20px;
  top: 6px;
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
  padding-left: 2px;
  padding-right: 2px;
  background-color: transparent;
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
  color: #3f51b5;
}

.page-aside .list-group.has-actions .list-group-item .item-actions .icon {
  margin: 0;
}

.page-aside .list-group.has-actions .list-group-item:hover .item-right {
  display: none;
}

.page-aside .list-group.has-actions .list-group-item:hover .item-actions {
  display: block;
}

.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
  color: #757575;
}

@media (max-width: 1199px) {
  .page-aside {
    width: 220px;
  }
  .page-aside-left .page-aside + .page-main {
    margin-left: 220px;
  }
  .page-aside-right .page-aside + .page-main {
    margin-right: 220px;
  }
  .page-aside-fixed.page-aside-left .site-footer {
    margin-left: 220px;
  }
  .page-aside-fixed.page-aside-right .site-footer {
    margin-right: 220px;
  }
}

@media (max-width: 767px) {
  .page-aside {
    position: fixed;
    top: 66.01px;
    width: 260px;
    border-color: transparent;
    z-index: 1100;
    overflow-y: visible;
    height: calc(100% - 66.01px);
    visibility: visible;
  }
  .site-navbar-collapse-show .page-aside {
    top: 132.02px;
    height: calc(100% - 132.02px);
  }
  .site-menubar-changing .page-aside,
  .site-menubar-open .page-aside {
    height: 100%;
  }
  .page-aside .page-aside-inner {
    height: 100%;
    background-color: white;
    border-right: 1px solid #e0e0e0;
  }
  .page-aside.open {
    left: 0;
  }
  .site-menubar-changing .page-aside.open,
  .site-menubar-open .page-aside.open {
    visibility: hidden;
  }
  .page-aside.open .page-aside-switch .md-chevron-right {
    display: none;
  }
  .page-aside.open .page-aside-switch .md-chevron-left {
    display: inline;
  }
  .page-aside-left .page-aside {
    left: -260px;
  }
  .page-aside-left .page-aside + .page-main {
    margin-left: 0;
  }
  .page-aside-right .page-aside {
    right: -260px;
  }
  .page-aside-right .page-aside + .page-main {
    margin-right: 0;
  }
  .page-aside-right .page-aside.open .page-aside-switch .md-chevron-right {
    display: inline;
  }
  .page-aside-right .page-aside.open .page-aside-switch .md-chevron-left {
    display: none;
  }
  .page-aside-left .page-aside {
    left: -260px !important;
  }
  .page-aside-left .page-aside.open {
    left: 0 !important;
  }
  .page-aside-left .site-footer {
    margin-left: 0 !important;
  }
  .page-aside-right .page-aside {
    right: -260px !important;
  }
  .page-aside-right .page-aside.open {
    left: auto;
    right: 0 !important;
  }
  .page-aside-right .site-footer {
    margin-right: 0 !important;
  }
  .page-aside-switch {
    display: block;
  }
}

.site-footer {
  padding: 10px 30px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  height: 44px;
  box-shadow: inset 0 0 44px rgba(0, 0, 0, 0.02);
}

.site-footer::after {
  display: block;
  clear: both;
  content: "";
}

.site-footer-legal {
  float: left;
  margin-left: 30px;
}

.site-footer-actions {
  float: right;
  margin-right: 30px;
}

.site-footer-right {
  float: right;
  margin-right: 30px;
}

.site-footer .scroll-to-top {
  color: #757575;
}

.site-footer .scroll-to-top, .site-footer .scroll-to-top:hover, .site-footer .scroll-to-top:active {
  text-decoration: none;
}

@media (max-width: 479px) {
  .site-footer {
    height: auto;
  }
}

@media (max-width: 479px) {
  .site-footer-legal, .site-footer-right, .site-footer-actions {
    display: block;
    float: none;
    text-align: center;
  }
}

@media (min-width: 1600px) {
  .site-footer {
    padding-left: calc((100% - 1600px) / 2 + 30px) !important;
    padding-right: calc((100% - 1600px) / 2 + 30px) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .site-footer {
    padding-left: calc((100% - 1200px) / 2 + 30px) !important;
    padding-right: calc((100% - 1200px) / 2 + 30px) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .site-footer {
    padding-left: calc((100% - 992px) / 2 + 30px) !important;
    padding-right: calc((100% - 992px) / 2 + 30px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .site-footer {
    padding-left: calc((100% - 768px) / 2 + 30px) !important;
    padding-right: calc((100% - 768px) / 2 + 30px) !important;
  }
}

.layout-full {
  height: 100%;
}

.layout-full .page {
  height: 100%;
  max-width: none;
  margin: 0 !important;
  padding: 0;
  background-color: transparent;
}

.layout-full > .loader {
  margin-left: 0 !important;
}

body.layout-full {
  padding-top: 0;
}

@media (min-width: 1200px) {
  .layout-boxed {
    background: #e0e0e0;
  }
  .layout-boxed, .layout-boxed .site-navbar {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}

.layout-boxed .slidePanel-right, .layout-boxed .slidePanel-left {
  top: 0;
  z-index: 1510;
}

.site-print {
  padding-top: 0;
}

.site-print .site-navbar,
.site-print .site-menubar,
.site-print .site-gridmenu,
.site-print .site-footer {
  display: none;
}

.site-print .page {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .site-navbar-collapsing {
    transition: padding-top .35s;
  }
  body.site-navbar-collapse-show {
    padding-top: 132.02px;
  }
  .site-navbar-collapse-show .slidePanel.slidePanel-right, .site-navbar-collapse-show .slidePanel.slidePanel-left {
    top: 132.02px;
    transition: top .35s;
  }
}

body.site-navbar-small {
  padding-top: 9.286rem;
}

.site-navbar-small .site-navbar {
  height: 4.286rem;
  min-height: 4.286rem;
}

.site-navbar-small .site-navbar .navbar-brand {
  height: 4.286rem;
  padding: 1.357rem 20px;
}

.site-navbar-small .site-navbar .navbar-nav {
  margin: 0.6785rem -0.9375rem;
}

@media (min-width: 768px) {
  .site-navbar-small .site-navbar .navbar-nav > li > a {
    padding-top: 1.357rem;
    padding-bottom: 1.357rem;
  }
}

.site-navbar-small .site-navbar .navbar-toggler {
  height: 4.286rem;
  padding: 1.357rem 0.9375rem;
}

.site-navbar-small .site-navbar .navbar-toolbar > li > a {
  padding-top: 1.357rem;
  padding-bottom: 1.357rem;
}

.site-navbar-small .site-navbar .navbar-nav > li > a.navbar-avatar, .site-navbar-small .site-navbar .navbar-toolbar > li > a.navbar-avatar {
  padding-top: 1.0715rem;
  padding-bottom: 1.0715rem;
}

.site-navbar-small .site-navbar .navbar-search-overlap .form-control {
  height: 4.286rem !important;
}

.site-navbar-small .site-menubar {
  top: 4.286rem;
}

.site-navbar-small .site-skintools {
  top: 11.429rem;
}

.site-navbar-small .slidePanel.slidePanel-right, .site-navbar-small .slidePanel.slidePanel-left {
  top: 9.286rem;
}

@media (max-width: 767px) {
  body.site-navbar-small {
    padding-top: 4.286rem;
  }
  body.site-navbar-small .site-menubar {
    top: 4.286rem;
    height: -webkit-calc($height);
    height: calc($height);
  }
  body.site-navbar-small .page-aside {
    top: 4.286rem;
  }
  body.site-navbar-small .site-skintools {
    top: 4.286rempxtoRem(30px);
  }
  body.site-navbar-small .slidePanel.slidePanel-right, body.site-navbar-small .slidePanel.slidePanel-left {
    top: 4.286rem;
  }
  body.site-navbar-small.site-navbar-collapse-show {
    padding-top: 8.572rem;
  }
  body.site-navbar-small.site-navbar-collapse-show .site-menubar {
    top: 8.572rem;
    height: -webkit-calc(100 - 8.572rem);
    height: calc(100 - 8.572rem);
  }
  body.site-navbar-small.site-navbar-collapse-show .page-aside {
    top: 8.572rem;
  }
  body.site-navbar-small.site-navbar-collapse-show .site-skintools {
    top: 10.715rem;
  }
  body.site-navbar-small.site-navbar-collapse-show .slidePanel.slidePanel-left, body.site-navbar-small.site-navbar-collapse-show .slidePanel.slidePanel-right {
    top: 8.572rem;
  }
}

@media (max-width: 767px) {
  .site-navbar .brand {
    display: none;
  }
  .site-navbar .brand-mobile {
    display: block;
  }
  .site-menubar {
    top: 66.01px;
    height: -webkit-calc(100% - 66.01px);
    height: calc(100% - 66.01px);
    backface-visibility: hidden;
    perspective: 1000;
    -ms-transform: translate(-260px, 0);
    transform: translate3d(-260px, 0, 0);
  }
  .site-navbar-collapse-show .site-menubar {
    top: 132.02px;
    height: -webkit-calc(100% - 132.02px);
    height: calc(100% - 132.02px);
  }
  .site-menubar-footer {
    visibility: hidden;
  }
  .site-menubar-open .site-menubar-footer {
    visibility: visible;
  }
  .disable-scrolling {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open .page {
    overflow: hidden;
    height: 100%;
    pointer-events: none;
  }
  .site-menubar-open .page, .site-menubar-open .site-footer {
    -ms-transform: translate(260px, 0);
    transform: translate3d(260px, 0, 0);
  }
  .site-menubar-changing {
    overflow: hidden;
  }
  .site-menubar-changing .page, .site-menubar-changing .site-footer {
    transition: transform .25s;
  }
  .site-menubar-hide .site-menubar,
  .site-menubar-open .site-menubar {
    transition: transform .25s, top .35s, height .35s;
  }
  .site-menubar-open .site-menubar {
    -ms-transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.radio-custom,
.checkbox-custom {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio-custom label,
.checkbox-custom label {
  min-height: 22px;
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer;
}

.radio-custom input[type="radio"],
.checkbox-custom input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 0;
  margin-bottom: 0;
}

.radio-custom.disabled label,
fieldset[disabled] .radio-custom label,
.checkbox-custom.disabled label,
fieldset[disabled]
.checkbox-custom label {
  cursor: not-allowed;
}

.input-group-addon .radio-custom,
.input-group-addon .checkbox-custom {
  margin: 0;
}

.checkbox-custom {
  padding-left: 20px;
}

.checkbox-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}

.checkbox-custom label:empty {
  padding-left: 0;
}

.checkbox-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e0e0e0;
  border-radius: 0.215rem;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out 0s;
}

.checkbox-custom label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  color: #757575;
}

.checkbox-custom input[type="checkbox"],
.checkbox-custom input[type="radio"] {
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkbox-custom input[type="checkbox"]:checked + label::before,
.checkbox-custom input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-custom input[type="radio"]:checked + label::before,
.checkbox-custom input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #e0e0e0;
  border-width: 10px;
  transition: all 300ms ease-in-out 0s;
}

.checkbox-custom input[type="checkbox"]:checked + label::after,
.checkbox-custom input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-custom input[type="radio"]:checked + label::after,
.checkbox-custom input[type="radio"]:checked + input[type="hidden"] + label::after {
  font-family: "Material Design Iconic";
  content: "";
}

.checkbox-custom input[type="checkbox"]:disabled,
.checkbox-custom input[type="radio"]:disabled {
  cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled + label,
.checkbox-custom input[type="checkbox"]:disabled + input[type="hidden"] + label,
.checkbox-custom input[type="radio"]:disabled + label,
.checkbox-custom input[type="radio"]:disabled + input[type="hidden"] + label {
  opacity: 0.65;
}

.checkbox-custom input[type="checkbox"]:disabled + label::before,
.checkbox-custom input[type="checkbox"]:disabled + input[type="hidden"] + label::before,
.checkbox-custom input[type="radio"]:disabled + label::before,
.checkbox-custom input[type="radio"]:disabled + input[type="hidden"] + label::before {
  background-color: #eeeeee;
  border-color: #e0e0e0;
  border-width: 1px;
  cursor: not-allowed;
}

.checkbox-custom.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox-custom.checkbox-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}

.checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}

.checkbox-default input[type="checkbox"]:checked + label::before,
.checkbox-default input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-default input[type="radio"]:checked + label::before,
.checkbox-default input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-width: 1px;
}

.checkbox-default input[type="checkbox"]:checked + label::after,
.checkbox-default input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-default input[type="radio"]:checked + label::after,
.checkbox-default input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #3f51b5;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-primary input[type="radio"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-primary input[type="radio"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #ffffff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-danger input[type="radio"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #f44336;
  border-color: #f44336;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-danger input[type="radio"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #ffffff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-info input[type="radio"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-info input[type="radio"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #ffffff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-warning input[type="radio"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #ff9800;
  border-color: #ff9800;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-warning input[type="radio"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #ffffff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-success input[type="radio"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="checkbox"]:checked + input[type="hidden"] + label::after,
.checkbox-success input[type="radio"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + input[type="hidden"] + label::after {
  color: #ffffff;
}

.checkbox-sm {
  padding-left: 18px;
}

.checkbox-sm label {
  padding-left: 8px;
}

.checkbox-sm label:empty {
  padding-left: 0;
}

.checkbox-sm label::before, .checkbox-sm label::after {
  width: 18px;
  height: 18px;
  margin-left: -18px;
}

.checkbox-sm label::after {
  font-size: 10px;
  line-height: 18px;
}

.checkbox-sm input[type="checkbox"],
.checkbox-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}

.checkbox-sm input[type="checkbox"]:checked + label::before,
.checkbox-sm input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-sm input[type="radio"]:checked + label::before,
.checkbox-sm input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-width: 9px;
}

.checkbox-lg {
  padding-left: 24px;
}

.checkbox-lg label {
  padding-left: 12px;
}

.checkbox-lg label:empty {
  padding-left: 0;
}

.checkbox-lg label::before, .checkbox-lg label::after {
  width: 24px;
  height: 24px;
  margin-left: -24px;
}

.checkbox-lg label::after {
  font-size: 14px;
  line-height: 24px;
}

.checkbox-lg input[type="checkbox"],
.checkbox-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}

.checkbox-lg input[type="checkbox"]:checked + label::before,
.checkbox-lg input[type="checkbox"]:checked + input[type="hidden"] + label::before,
.checkbox-lg input[type="radio"]:checked + label::before,
.checkbox-lg input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-width: 12px;
}

.radio-custom {
  padding-left: 20px;
}

.radio-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}

.radio-custom label:empty {
  padding-left: 0;
}

.radio-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: border 300ms ease-in-out 0s, color 300ms ease-in-out 0s;
}

.radio-custom label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 6px;
  height: 6px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border: 2px solid #757575;
  border-radius: 50%;
  background-color: transparent;
  transform: scale(0, 0);
  transition: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio-custom input[type="radio"] {
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.radio-custom input[type="radio"]:checked + label::before,
.radio-custom input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #e0e0e0;
  border-width: 10px;
}

.radio-custom input[type="radio"]:checked + label::after,
.radio-custom input[type="radio"]:checked + input[type="hidden"] + label::after {
  transform: scale(1, 1);
}

.radio-custom input[type="radio"]:disabled {
  cursor: not-allowed;
}

.radio-custom input[type="radio"]:disabled + label,
.radio-custom input[type="radio"]:disabled + input[type="hidden"] + label {
  opacity: 0.65;
}

.radio-custom input[type="radio"]:disabled + label::before,
.radio-custom input[type="radio"]:disabled + input[type="hidden"] + label::before {
  cursor: not-allowed;
}

.radio-custom.radio-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}

.radio-inline + .radio-inline {
  margin-left: 20px;
}

.radio-default input[type="radio"]:checked + label::before,
.radio-default input[type="radio"]:checked + input[type="hidden"] + label::before {
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-width: 1px;
}

.radio-default input[type="radio"]:checked + label::after,
.radio-default input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #3f51b5;
}

.radio-primary input[type="radio"]:checked + label::before,
.radio-primary input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #3f51b5;
}

.radio-primary input[type="radio"]:checked + label::after,
.radio-primary input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #ffffff;
}

.radio-danger input[type="radio"]:checked + label::before,
.radio-danger input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #f44336;
}

.radio-danger input[type="radio"]:checked + label::after,
.radio-danger input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #ffffff;
}

.radio-info input[type="radio"]:checked + label::before,
.radio-info input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #00bcd4;
}

.radio-info input[type="radio"]:checked + label::after,
.radio-info input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #ffffff;
}

.radio-warning input[type="radio"]:checked + label::before,
.radio-warning input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #ff9800;
}

.radio-warning input[type="radio"]:checked + label::after,
.radio-warning input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #ffffff;
}

.radio-success input[type="radio"]:checked + label::before,
.radio-success input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-color: #4caf50;
}

.radio-success input[type="radio"]:checked + label::after,
.radio-success input[type="radio"]:checked + input[type="hidden"] + label::after {
  border-color: #ffffff;
}

.radio-sm {
  padding-left: 18px;
}

.radio-sm label {
  padding-left: 8px;
}

.radio-sm label:empty {
  padding-left: 0;
}

.radio-sm label::before {
  width: 18px;
  height: 18px;
  margin-left: -20px;
}

.radio-sm label::after {
  width: 4px;
  height: 4px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border-width: 2px;
}

.radio-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}

.radio-sm input[type="radio"]:checked + label::before,
.radio-sm input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-width: 9px;
}

.radio-lg {
  padding-left: 24px;
}

.radio-lg label {
  padding-left: 12px;
}

.radio-lg label:empty {
  padding-left: 0;
}

.radio-lg label::before {
  width: 24px;
  height: 24px;
  margin-left: -20px;
}

.radio-lg label::after {
  width: 8px;
  height: 8px;
  left: 8px;
  top: 8px;
  margin-left: -20px;
  border-width: 2px;
}

.radio-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}

.radio-lg input[type="radio"]:checked + label::before,
.radio-lg input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-width: 12px;
}

@media (min-width: 768px) {
  .form-inline .radio-custom,
  .form-inline .checkbox-custom {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio-custom label,
  .form-inline .checkbox-custom label {
    padding-left: 0;
  }
  .form-inline .radio-custom input[type="radio"],
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .radio-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
  }
  .form-inline .radio-custom input[type="radio"] {
    position: absolute;
    margin-left: -20px;
  }
}

.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.501rem;
}

.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  min-height: 2.073rem;
}

.form-material {
  position: relative;
}

.form-material.floating {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-material.floating + .form-material.floating {
  margin-top: 40px;
}

.form-material .form-control {
  padding-left: 0;
  padding-right: 0;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  background-color: transparent;
  transition: background 0s ease-out;
}

.form-material .form-control, .form-material .form-control:focus, .form-material .form-control.focus {
  float: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background-image: linear-gradient(#3f51b5, #3f51b5), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material .form-control {
  border-bottom: 2px solid #e0e0e0;
}

.form-material .form-control::-webkit-input-placeholder {
  color: #9e9e9e;
}

.form-material .form-control::-moz-placeholder {
  color: #9e9e9e;
}

.form-material .form-control:-ms-input-placeholder {
  color: #9e9e9e;
}

.form-material .form-control:disabled::-webkit-input-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:disabled::-moz-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:disabled:-ms-input-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:focus, .form-material .form-control.focus {
  outline: none;
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}

.no-cssgradients .form-material .form-control:focus, .no-cssgradients .form-material .form-control.focus {
  background: transparent;
  border-bottom: 2px solid #3f51b5;
}

.form-material .form-control:disabled, .form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control {
  background: transparent;
  background: transparent;
  border-bottom: 1px dashed #bdbdbd;
}

.form-material .form-control:disabled ~ .floating-label, .form-material .form-control[disabled] ~ .floating-label,
fieldset[disabled] .form-material .form-control ~ .floating-label {
  color: #bdbdbd;
}

.form-material textarea.form-control, .form-material select[multiple], .form-material select[size] {
  height: auto;
}

.form-material.form-group .form-control-label {
  font-weight: 500;
}

.form-material.form-group .form-control-label {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .form-horizontal .form-material.form-group .form-control-label {
    padding-top: 7px;
  }
}

.form-material .floating-label {
  font-size: 1rem;
  color: #757575;
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: 0.3s ease all;
}

.form-material .floating-label.floating-label-static {
  position: relative;
  top: auto;
  display: block;
}

.form-material [class*="col-"] > .floating-label {
  left: 0.9375rem;
}

.form-material .form-control ~ .floating-label {
  font-size: 1rem;
  top: 0.571429rem;
}

.form-material .form-control:focus ~ .floating-label, .form-material .form-control.focus ~ .floating-label, .form-material .form-control:not(.empty) ~ .floating-label {
  top: -0.8rem;
  font-size: 0.8rem;
}

.form-material .form-control:-webkit-autofill ~ .floating-label {
  top: -0.8rem;
  font-size: 0.8rem;
}

.form-material .form-control-sm ~ .floating-label {
  font-size: 0.858rem;
  top: 0.429rem;
}

.form-material .form-control-sm:focus ~ .floating-label, .form-material .form-control-sm.focus ~ .floating-label, .form-material .form-control-sm:not(.empty) ~ .floating-label {
  top: -0.6864rem;
  font-size: 0.6864rem;
}

.form-material .form-control-sm:-webkit-autofill ~ .floating-label {
  top: -0.6864rem;
  font-size: 0.6864rem;
}

.form-material .form-control-lg ~ .floating-label {
  font-size: 1.286rem;
  top: 0.428667rem;
}

.form-material .form-control-lg:focus ~ .floating-label, .form-material .form-control-lg.focus ~ .floating-label, .form-material .form-control-lg:not(.empty) ~ .floating-label {
  top: -1.0288rem;
  font-size: 1.0288rem;
}

.form-material .form-control-lg:-webkit-autofill ~ .floating-label {
  top: -1.0288rem;
  font-size: 1.0288rem;
}

.form-material .form-control:focus ~ .floating-label, .form-material .form-control.focus ~ .floating-label, .form-material .form-control:not(.empty) ~ .floating-label {
  font-weight: 500;
}

.form-material .form-control:-webkit-autofill ~ .floating-label {
  font-weight: 500;
}

.form-material .form-control:focus ~ .floating-label, .form-material .form-control.focus ~ .floating-label {
  color: #3f51b5;
}

.form-material textarea.form-control {
  resize: none;
  padding-bottom: 0.429rem;
}

.form-material.floating textarea.form-control {
  padding-top: 0.429rem;
}

.form-material select.form-control {
  border: 0;
  border-radius: 0;
}

.form-material:not(.floating) .form-control-label + select[multiple] {
  margin-top: 5px;
}

.form-material .hint {
  position: absolute;
  font-size: 80%;
  display: none;
}

.form-material .form-control:focus ~ .hint, .form-material .form-control.focus ~ .hint {
  display: block;
}

.form-material .form-control:not(.empty):invalid ~ .floating-label, .form-material .form-control.focus:invalid ~ .floating-label {
  color: #f44336;
}

.form-material .form-control:invalid {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}

.form-material.form-group.has-warning .form-control:focus, .form-material.form-group.has-warning .form-control.focus, .form-material.form-group.has-warning .form-control:not(.empty) {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-warning .form-control:focus, .no-cssgradients .form-material.form-group.has-warning .form-control.focus, .no-cssgradients .form-material.form-group.has-warning .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #ff9800;
}

.form-material.form-group.has-warning .form-control:-webkit-autofill {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-warning .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #ff9800;
}

.form-material.form-group.has-warning .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}

.form-material.form-group.has-warning .form-control-label {
  color: #ff9800;
}

.form-material.form-group.has-warning .form-control:focus ~ .floating-label, .form-material.form-group.has-warning .form-control.focus ~ .floating-label, .form-material.form-group.has-warning .form-control:not(.empty) ~ .floating-label {
  color: #ff9800;
}

.form-material.form-group.has-warning .form-control:-webkit-autofill ~ .floating-label {
  color: #ff9800;
}

.form-material.form-group.has-danger .form-control:focus, .form-material.form-group.has-danger .form-control.focus, .form-material.form-group.has-danger .form-control:not(.empty) {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-danger .form-control:focus, .no-cssgradients .form-material.form-group.has-danger .form-control.focus, .no-cssgradients .form-material.form-group.has-danger .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #f44336;
}

.form-material.form-group.has-danger .form-control:-webkit-autofill {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-danger .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #f44336;
}

.form-material.form-group.has-danger .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}

.form-material.form-group.has-danger .form-control-label {
  color: #f44336;
}

.form-material.form-group.has-danger .form-control:focus ~ .floating-label, .form-material.form-group.has-danger .form-control.focus ~ .floating-label, .form-material.form-group.has-danger .form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}

.form-material.form-group.has-danger .form-control:-webkit-autofill ~ .floating-label {
  color: #f44336;
}

.form-material.form-group.has-success .form-control:focus, .form-material.form-group.has-success .form-control.focus, .form-material.form-group.has-success .form-control:not(.empty) {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-success .form-control:focus, .no-cssgradients .form-material.form-group.has-success .form-control.focus, .no-cssgradients .form-material.form-group.has-success .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #4caf50;
}

.form-material.form-group.has-success .form-control:-webkit-autofill {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-success .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #4caf50;
}

.form-material.form-group.has-success .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}

.form-material.form-group.has-success .form-control-label {
  color: #4caf50;
}

.form-material.form-group.has-success .form-control:focus ~ .floating-label, .form-material.form-group.has-success .form-control.focus ~ .floating-label, .form-material.form-group.has-success .form-control:not(.empty) ~ .floating-label {
  color: #4caf50;
}

.form-material.form-group.has-success .form-control:-webkit-autofill ~ .floating-label {
  color: #4caf50;
}

.form-material.form-group.has-info .form-control:focus, .form-material.form-group.has-info .form-control.focus, .form-material.form-group.has-info .form-control:not(.empty) {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-info .form-control:focus, .no-cssgradients .form-material.form-group.has-info .form-control.focus, .no-cssgradients .form-material.form-group.has-info .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}

.form-material.form-group.has-info .form-control:-webkit-autofill {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-info .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}

.form-material.form-group.has-info .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}

.form-material.form-group.has-info .form-control-label {
  color: #00bcd4;
}

.form-material.form-group.has-info .form-control:focus ~ .floating-label, .form-material.form-group.has-info .form-control.focus ~ .floating-label, .form-material.form-group.has-info .form-control:not(.empty) ~ .floating-label {
  color: #00bcd4;
}

.form-material.form-group.has-info .form-control:-webkit-autofill ~ .floating-label {
  color: #00bcd4;
}

.form-material .input-group .form-control-wrap {
  flex: 1 1 auto;
  margin-right: 5px;
  margin-left: 5px;
}

.form-material .input-group .form-control-wrap .form-control {
  float: none;
  width: 100%;
}

.form-material .input-group .input-group-addon {
  border: 0;
  background: transparent;
}

.form-material .input-group .input-group-btn .btn {
  border-radius: 0.286rem;
  margin: 0;
}

.form-material input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-control-wrap {
  position: relative;
}

.loader {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  transform: translateZ(0);
}

.loader-default {
  width: 1em;
  height: 1em;
  background-color: #9e9e9e;
  border-radius: 100%;
  animation: loader-default 1.0s infinite ease-in-out;
}

.loader-grill {
  width: .25em;
  height: .5em;
  background: #9e9e9e;
  animation: default-grill 1.0s infinite ease-in-out -.16s;
}

.loader-grill:before, .loader-grill:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #9e9e9e;
  animation: default-grill 1.0s infinite ease-in-out;
}

.loader-grill:before {
  left: -.375em;
  animation-delay: -.32s;
}

.loader-grill:after {
  left: .375em;
}

.loader-circle {
  width: 1em;
  height: 1em;
  border-top: 0.125em solid rgba(158, 158, 158, 0.5);
  border-right: 0.125em solid rgba(158, 158, 158, 0.5);
  border-bottom: 0.125em solid rgba(158, 158, 158, 0.5);
  border-left: 0.125em solid #9e9e9e;
  border-radius: 50%;
  animation: loader-circle 1.1s infinite linear;
}

.loader-round-circle {
  width: 1em;
  height: 1em;
  font-size: 10px;
  border-radius: 50%;
  animation: loader-round-circle 1.3s infinite linear;
}

.loader-tadpole {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: loader-tadpole 1.7s infinite ease;
}

.loader-ellipsis {
  top: -.625em;
  width: .625em;
  height: .625em;
  border-radius: 50%;
  animation: loader-ellipsis 1.8s infinite ease-in-out both -.16s;
}

.loader-ellipsis:before, .loader-ellipsis:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  animation: loader-ellipsis 1.8s infinite ease-in-out both;
}

.loader-ellipsis:before {
  left: -.875em;
  animation-delay: -.32s;
}

.loader-ellipsis:after {
  left: .875em;
}

.loader-dot {
  width: 2em;
  height: 2em;
  animation: loader-dot-rotate 2.0s infinite linear;
}

.loader-dot:before, .loader-dot:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 60%;
  content: '';
  background: #9e9e9e;
  border-radius: 100%;
  animation: loader-dot-bounce 2.0s infinite ease-in-out;
}

.loader-dot:after {
  top: auto;
  bottom: 0;
  animation-delay: -1.0s;
}

.loader-bounce {
  width: 1.5em;
  height: 1.5em;
}

.loader-bounce:before, .loader-bounce:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #9e9e9e;
  border-radius: 50%;
  opacity: .6;
  animation: loader-bounce 2.0s infinite ease-in-out;
}

.loader-bounce:after {
  animation-delay: -1.0s;
}

.loader-cube {
  width: 2em;
  height: 2em;
}

.loader-cube:before, .loader-cube:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 25%;
  content: '';
  background: #9e9e9e;
  animation: loader-cube 2.0s infinite ease-in-out;
}

.loader-cube:after {
  animation-delay: -1.0s;
}

.loader-rotate-plane {
  width: 1em;
  height: 1em;
  background: #9e9e9e;
  animation: loader-rotate-plane 1.2s infinite ease-in-out;
}

.loader-folding-cube {
  width: .8em;
  height: .8em;
  transform: rotate(45deg) translateZ(0);
}

.loader-folding-cube:before, .loader-folding-cube:after {
  position: absolute;
  width: 0;
  height: 0;
  content: '';
  background: #9e9e9e;
}

.loader-folding-cube:before {
  left: 0;
  bottom: 50%;
  animation-delay: -0.6s;
  animation: loader-folding-cube-before 2.4s infinite ease-in-out;
}

.loader-folding-cube:after {
  top: 50%;
  right: 0;
  animation: loader-folding-cube-after 2.4s infinite ease-in-out;
}

.loader-cube-grid {
  top: -.6em;
  width: .3em;
  height: .3em;
  animation: loader-cube-grid 1.2s infinite ease-in-out;
}

.loader-cube-grid:before, .loader-cube-grid:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.loader-cube-grid:before {
  animation: loader-cube-grid-before 1.2s infinite ease-in-out;
}

.loader-cube-grid:after {
  animation: loader-cube-grid-after 1.2s infinite ease-in-out;
}

.side-panel-loading,
body > .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20px;
}

.site-menubar-unfold > .loader {
  margin-left: 130px;
}

.site-menubar-fold > .loader {
  margin-left: 45px;
}

.site-menubar-hide.site-menubar-unfold > .loader {
  margin-left: 0;
}

.index {
  position: relative;
  opacity: 0;
  animation-fill-mode: both;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #3949ab;
  z-index: 999999;
}

.loader-content {
  margin: 50vh auto 0;
  transform: translateY(-50%);
  text-align: center;
  text-transform: uppercase;
}

.loader-content h2 {
  color: #fff;
  font-weight: 500;
}

.loader-index {
  margin-top: 20px;
}

.loader-index > div {
  width: 4px;
  height: 25px;
  border-radius: 2px;
  margin: 2px;
  background: #fff;
  animation-fill-mode: both;
  display: inline-block;
  animation: loader-index 0.9s -0.8s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.loader-index > div:nth-child(2), .loader-index > div:nth-child(4) {
  animation-delay: -0.6s !important;
}

.loader-index > div:nth-child(1), .loader-index > div:nth-child(5) {
  animation-delay: -0.4s !important;
}

.loader-index > div:nth-child(6) {
  animation-delay: -0.2s !important;
}

@keyframes loader-index {
  0% {
    transform: scaley(1);
  }
  50% {
    transform: scaley(0.4);
  }
  100% {
    transform: scaley(1);
  }
}

@keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes default-grill {
  0%,
  80%,
  100% {
    height: 1em;
    box-shadow: 0 0 #9e9e9e;
  }
  40% {
    height: 1.2em;
    box-shadow: 0 -0.25em #9e9e9e;
  }
}

@keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-round-circle {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em #9e9e9e, 2em -2em 0 0 #9e9e9e, 3em 0 0 -0.5em #9e9e9e, 2em 2em 0 -0.5em #9e9e9e, 0 3em 0 -0.5em #9e9e9e, -2em 2em 0 -0.5em #9e9e9e, -3em 0 0 -0.5em #9e9e9e, -2em -2em 0 0 #9e9e9e;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #9e9e9e, 2em -2em 0 0.2em #9e9e9e, 3em 0 0 0 #9e9e9e, 2em 2em 0 -0.5em #9e9e9e, 0 3em 0 -0.5em #9e9e9e, -2em 2em 0 -0.5em #9e9e9e, -3em 0 0 -0.5em #9e9e9e, -2em -2em 0 -0.5em #9e9e9e;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #9e9e9e, 2em -2em 0 0 #9e9e9e, 3em 0 0 0.2em #9e9e9e, 2em 2em 0 0 #9e9e9e, 0 3em 0 -0.5em #9e9e9e, -2em 2em 0 -0.5em #9e9e9e, -3em 0 0 -0.5em #9e9e9e, -2em -2em 0 -0.5em #9e9e9e;
  }
  37.5% {
    box-shadow: 0 -3em 0 -0.5em #9e9e9e, 2em -2em 0 -0.5em #9e9e9e, 3em 0 0 0 #9e9e9e, 2em 2em 0 0.2em #9e9e9e, 0 3em 0 0 #9e9e9e, -2em 2em 0 -0.5em #9e9e9e, -3em 0 0 -0.5em #9e9e9e, -2em -2em 0 -0.5em #9e9e9e;
  }
  50% {
    box-shadow: 0 -3em 0 -0.5em #9e9e9e, 2em -2em 0 -0.5em #9e9e9e, 3em 0 0 -0.5em #9e9e9e, 2em 2em 0 0 #9e9e9e, 0 3em 0 0.2em #9e9e9e, -2em 2em 0 0 #9e9e9e, -3em 0 0 -0.5em #9e9e9e, -2em -2em 0 -0.5em #9e9e9e;
  }
  62.5% {
    box-shadow: 0 -3em 0 -0.5em #9e9e9e, 2em -2em 0 -0.5em #9e9e9e, 3em 0 0 -0.5em #9e9e9e, 2em 2em 0 -0.5em #9e9e9e, 0 3em 0 0 #9e9e9e, -2em 2em 0 0.2em #9e9e9e, -3em 0 0 0 #9e9e9e, -2em -2em 0 -0.5em #9e9e9e;
  }
  75% {
    box-shadow: 0 -3em 0 -0.5em #9e9e9e, 2em -2em 0 -0.5em #9e9e9e, 3em 0 0 -0.5em #9e9e9e, 2em 2em 0 -0.5em #9e9e9e, 0 3em 0 -0.5em #9e9e9e, -2em 2em 0 0 #9e9e9e, -3em 0 0 0.2em #9e9e9e, -2em -2em 0 0 #9e9e9e;
  }
  87.5% {
    box-shadow: 0 -3em 0 0 #9e9e9e, 2em -2em 0 -0.5em #9e9e9e, 3em 0 0 -0.5em #9e9e9e, 2em 2em 0 -0.5em #9e9e9e, 0 3em 0 -0.5em #9e9e9e, -2em 2em 0 0 #9e9e9e, -3em 0 0 0 #9e9e9e, -2em -2em 0 0.2em #9e9e9e;
  }
}

@keyframes loader-tadpole {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, 0 -0.83em 0 -0.42em #9e9e9e, 0 -0.83em 0 -0.44em #9e9e9e, 0 -0.83em 0 -0.46em #9e9e9e, 0 -0.83em 0 -0.477em #9e9e9e;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, 0 -0.83em 0 -0.42em #9e9e9e, 0 -0.83em 0 -0.44em #9e9e9e, 0 -0.83em 0 -0.46em #9e9e9e, 0 -0.83em 0 -0.477em #9e9e9e;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, -0.087em -0.825em 0 -0.42em #9e9e9e, -0.173em -0.812em 0 -0.44em #9e9e9e, -0.256em -0.789em 0 -0.46em #9e9e9e, -0.297em -0.775em 0 -0.477em #9e9e9e;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, -0.338em -0.758em 0 -0.42em #9e9e9e, -0.555em -0.617em 0 -0.44em #9e9e9e, -0.671em -0.488em 0 -0.46em #9e9e9e, -0.749em -0.34em 0 -0.477em #9e9e9e;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, -0.377em -0.74em 0 -0.42em #9e9e9e, -0.645em -0.522em 0 -0.44em #9e9e9e, -0.775em -0.297em 0 -0.46em #9e9e9e, -0.82em -0.09em 0 -0.477em #9e9e9e;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em #9e9e9e, 0 -0.83em 0 -0.42em #9e9e9e, 0 -0.83em 0 -0.44em #9e9e9e, 0 -0.83em 0 -0.46em #9e9e9e, 0 -0.83em 0 -0.477em #9e9e9e;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
  }
  40% {
    box-shadow: 0 0.625em 0 0 #9e9e9e;
  }
}

@keyframes loader-dot-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-dot-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-cube {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25% {
    -webkit-transform: translateX(1.5em) rotate(-90deg) scale(0.5);
    transform: translateX(1.5em) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(1.5em) translateY(1.5em) rotate(-179deg);
    transform: translateX(1.5em) translateY(1.5em) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(1.5em) translateY(1.5em) rotate(-180deg);
    transform: translateX(1.5em) translateY(1.5em) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(1.5em) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(1.5em) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-rotate-plane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes loader-folding-cube-before {
  0% {
    height: 0;
    width: 50%;
  }
  7.5% {
    height: 50%;
    width: 50%;
  }
  12.5% {
    height: 50%;
    width: 50%;
  }
  20% {
    height: 50%;
    width: 100%;
  }
  25% {
    height: 50%;
    width: 100%;
  }
  50% {
    left: auto;
    right: 0;
    height: 50%;
    width: 100%;
  }
  57.5% {
    height: 50%;
    width: 50%;
  }
  62.5% {
    height: 50%;
    width: 50%;
  }
  70% {
    width: 50%;
    height: 0;
  }
  75% {
    width: 50%;
    height: 0;
  }
}

@keyframes loader-folding-cube-after {
  0% {
    height: 0;
    width: 50%;
  }
  7.5% {
    height: 50%;
    width: 50%;
  }
  12.5% {
    height: 50%;
    width: 50%;
  }
  20% {
    height: 50%;
    width: 100%;
  }
  25% {
    height: 50%;
    width: 100%;
  }
  50% {
    left: 0;
    right: auto;
    height: 50%;
    width: 100%;
  }
  57.5% {
    height: 50%;
    width: 50%;
  }
  62.5% {
    height: 50%;
    width: 50%;
  }
  75% {
    width: 50%;
    height: 0;
  }
}

@keyframes loader-cube-grid {
  15% {
    box-shadow: -0.3em 0.3em 0 -0.3em #9e9e9e, 0 0.6em 0 -0.3em #9e9e9e, 0.3em 0.9em 0 -0.3em #9e9e9e;
  }
  30% {
    box-shadow: -0.3em 0.3em 0 0 #9e9e9e, 0 0.6em 0 0 #9e9e9e, 0.3em 0.9em 0 0 #9e9e9e;
  }
  70% {
    box-shadow: -0.3em 0.3em 0 0 #9e9e9e, 0 0.6em 0 0 #9e9e9e, 0.3em 0.9em 0 0 #9e9e9e;
  }
  85% {
    box-shadow: -0.3em 0.3em 0 -0.3em #9e9e9e, 0 0.6em 0 -0.3em #9e9e9e, 0.3em 0.9em 0 -0.3em #9e9e9e;
  }
}

@keyframes loader-cube-grid-before {
  0% {
    box-shadow: -0.3em 0.9em 0 -0.3em #9e9e9e, 0 0.3em 0 -0.3em #9e9e9e, 0.3em 0.6em 0 -0.3em #9e9e9e;
  }
  15% {
    box-shadow: -0.3em 0.9em 0 0 #9e9e9e, 0 0.3em 0 -0.3em #9e9e9e, 0.3em 0.6em 0 -0.3em #9e9e9e;
  }
  22.5% {
    box-shadow: -0.3em 0.9em 0 0 #9e9e9e, 0 0.3em 0 -0.3em #9e9e9e, 0.3em 0.6em 0 -0.3em #9e9e9e;
  }
  37.5% {
    box-shadow: -0.3em 0.9em 0 0 #9e9e9e, 0 0.3em 0 0 #9e9e9e, 0.3em 0.6em 0 0 #9e9e9e;
  }
  55% {
    box-shadow: -0.3em 0.9em 0 0 #9e9e9e, 0 0.3em 0 0 #9e9e9e, 0.3em 0.6em 0 0 #9e9e9e;
  }
  70% {
    box-shadow: -0.3em 0.9em 0 -0.3em #9e9e9e, 0 0.3em 0 0 #9e9e9e, 0.3em 0.6em 0 0 #9e9e9e;
  }
  77.5% {
    box-shadow: -0.3em 0.9em 0 -0.3em #9e9e9e, 0 0.3em 0 0 #9e9e9e, 0.3em 0.6em 0 0 #9e9e9e;
  }
  92.5% {
    box-shadow: -0.3em 0.9em 0 -0.3em #9e9e9e, 0 0.3em 0 -0.3em #9e9e9e, 0.3em 0.6em 0 -0.3em #9e9e9e;
  }
}

@keyframes loader-cube-grid-after {
  7.5% {
    box-shadow: -0.3em 0.6em 0 -0.3em #9e9e9e, 0 0.9em 0 -0.3em #9e9e9e, 0.3em 0.3em 0 -0.3em #9e9e9e;
  }
  22.5% {
    box-shadow: -0.3em 0.6em 0 0 #9e9e9e, 0 0.9em 0 0 #9e9e9e, 0.3em 0.3em 0 -0.3em #9e9e9e;
  }
  30% {
    box-shadow: -0.3em 0.6em 0 0 #9e9e9e, 0 0.9em 0 0 #9e9e9e, 0.3em 0.3em 0 -0.3em #9e9e9e;
  }
  45% {
    box-shadow: -0.3em 0.6em 0 0 #9e9e9e, 0 0.9em 0 0 #9e9e9e, 0.3em 0.3em 0 0 #9e9e9e;
  }
  62.5% {
    box-shadow: -0.3em 0.6em 0 0 #9e9e9e, 0 0.9em 0 0 #9e9e9e, 0.3em 0.3em 0 0 #9e9e9e;
  }
  77.5% {
    box-shadow: -0.3em 0.6em 0 -0.3em #9e9e9e, 0 0.9em 0 -0.3em #9e9e9e, 0.3em 0.3em 0 0 #9e9e9e;
  }
  85% {
    box-shadow: -0.3em 0.6em 0 -0.3em #9e9e9e, 0 0.9em 0 -0.3em #9e9e9e, 0.3em 0.3em 0 0 #9e9e9e;
  }
  100% {
    box-shadow: -0.3em 0.6em 0 -0.3em #9e9e9e, 0 0.9em 0 -0.3em #9e9e9e, 0.3em 0.3em 0 -0.3em #9e9e9e;
  }
}

[class*=animation-] {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animation-hover:not(:hover),
.animation-hover:not(:hover) [class*=animation-],
.touch .animation-hover:not(.hover),
.touch .animation-hover:not(.hover) [class*=animation-] {
  -webkit-animation-name: none;
  animation-name: none;
}

.animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

.animation-repeat {
  animation-iteration-count: infinite;
}

.animation-fade {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.animation-scale {
  -webkit-animation-name: scale-12;
  animation-name: scale-12;
}

.animation-scale-up {
  -webkit-animation-name: fade-scale-02;
  animation-name: fade-scale-02;
}

.animation-scale-down {
  -webkit-animation-name: fade-scale-18;
  animation-name: fade-scale-18;
}

.animation-slide-top {
  -webkit-animation-name: slide-top;
  animation-name: slide-top;
}

.animation-slide-bottom {
  -webkit-animation-name: slide-bottom;
  animation-name: slide-bottom;
}

.animation-slide-left {
  -webkit-animation-name: slide-left;
  animation-name: slide-left;
}

.animation-slide-right {
  -webkit-animation-name: slide-right;
  animation-name: slide-right;
}

.animation-shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

.animation-duration-10 {
  animation-duration: 15s;
}

.animation-duration-9 {
  animation-duration: 9s;
}

.animation-duration-8 {
  animation-duration: 8s;
}

.animation-duration-7 {
  animation-duration: 7s;
}

.animation-duration-6 {
  animation-duration: 6s;
}

.animation-duration-5 {
  animation-duration: 5s;
}

.animation-duration-4 {
  animation-duration: 4s;
}

.animation-duration-3 {
  animation-duration: 3s;
}

.animation-duration-2 {
  animation-duration: 2s;
}

.animation-duration-1 {
  animation-duration: 1s;
}

.animation-delay-100 {
  animation-duration: 100ms;
}

.animation-duration-250 {
  animation-duration: 250ms;
}

.animation-duration-300 {
  animation-duration: 300ms;
}

.animation-duration-500 {
  animation-duration: 500ms;
}

.animation-duration-750 {
  animation-duration: 750ms;
}

.animation-delay-1000 {
  animation-delay: 1s;
}

.animation-delay-900 {
  animation-delay: 900ms;
}

.animation-delay-800 {
  animation-delay: 800ms;
}

.animation-delay-700 {
  animation-delay: 700ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

.animation-delay-500 {
  animation-delay: 500ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-100 {
  animation-delay: 100ms;
}

.animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.animation-middle-left {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.animation-easing-easeInOut {
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.animation-easing-easeInQuad {
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.animation-easing-easeInCubic {
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.animation-easing-easeInQuart {
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.animation-easing-easeInQuint {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.animation-easing-easeInSine {
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

.animation-easing-easeInExpo {
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

.animation-easing-easeInCirc {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.animation-easing-easeInBack {
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.animation-easing-eastOutQuad {
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.animation-easing-easeOutCubic {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animation-easing-easeOutQuart {
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.animation-easing-easeOutQuint {
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.animation-easing-easeOutSine {
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

.animation-easing-easeOutExpo {
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.animation-easing-easeOutCirc {
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animation-easing-easeOutBack {
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-easing-easeInOutQuad {
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.animation-easing-easeInOutCubic {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.animation-easing-easeInOutQuart {
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.animation-easing-easeInOutQuint {
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.animation-easing-easeInOutSine {
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.animation-easing-easeInOutExpo {
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
}

.animation-easing-easeInOutCirc {
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.animation-easing-easeInOutBack {
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.animation-easing-easeInOutElastic {
  animation-timing-function: cubic-bezier(1, -0.56, 0, 1.455);
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scale-12 {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-12 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}

.bg-red-50 {
  background-color: #ffebee !important;
}

.bg-red-100 {
  background-color: #ffcdd2 !important;
}

.bg-red-200 {
  background-color: #ef9a9a !important;
}

.bg-red-300 {
  background-color: #e57373 !important;
}

.bg-red-400 {
  background-color: #ef5350 !important;
}

.bg-red-500 {
  background-color: #f44336 !important;
}

.bg-red-600 {
  background-color: #e53935 !important;
}

.bg-red-700 {
  background-color: #d32f2f !important;
}

.bg-red-800 {
  background-color: #c62828 !important;
}

.bg-red-900 {
  background-color: #b71c1c !important;
}

.bg-red-a100 {
  background-color: #ff8a80 !important;
}

.bg-red-a200 {
  background-color: #ff5252 !important;
}

.bg-red-a300 {
  background-color: #ff1744 !important;
}

.bg-red-a400 {
  background-color: #d50000 !important;
}

.red-50 {
  color: #ffebee !important;
}

.red-100 {
  color: #ffcdd2 !important;
}

.red-200 {
  color: #ef9a9a !important;
}

.red-300 {
  color: #e57373 !important;
}

.red-400 {
  color: #ef5350 !important;
}

.red-500 {
  color: #f44336 !important;
}

.red-600 {
  color: #e53935 !important;
}

.red-700 {
  color: #d32f2f !important;
}

.red-800 {
  color: #c62828 !important;
}

.red-900 {
  color: #b71c1c !important;
}

.red-a100 {
  color: #ff8a80 !important;
}

.red-a200 {
  color: #ff5252 !important;
}

.red-a300 {
  color: #ff1744 !important;
}

.red-a400 {
  color: #d50000 !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
}

.bg-pink-100 {
  background-color: #f8bbd0 !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-pink-300 {
  background-color: #f06292 !important;
}

.bg-pink-400 {
  background-color: #ec407a !important;
}

.bg-pink-500 {
  background-color: #e91e63 !important;
}

.bg-pink-600 {
  background-color: #d81b60 !important;
}

.bg-pink-700 {
  background-color: #c2185b !important;
}

.bg-pink-800 {
  background-color: #ad1457 !important;
}

.bg-pink-900 {
  background-color: #880e4f !important;
}

.bg-pink-a100 {
  background-color: #ff80ab !important;
}

.bg-pink-a200 {
  background-color: #ff4081 !important;
}

.bg-pink-a300 {
  background-color: #f50057 !important;
}

.bg-pink-a400 {
  background-color: #c51162 !important;
}

.pink-50 {
  color: #fce4ec !important;
}

.pink-100 {
  color: #f8bbd0 !important;
}

.pink-200 {
  color: #f48fb1 !important;
}

.pink-300 {
  color: #f06292 !important;
}

.pink-400 {
  color: #ec407a !important;
}

.pink-500 {
  color: #e91e63 !important;
}

.pink-600 {
  color: #d81b60 !important;
}

.pink-700 {
  color: #c2185b !important;
}

.pink-800 {
  color: #ad1457 !important;
}

.pink-900 {
  color: #880e4f !important;
}

.pink-a100 {
  color: #ff80ab !important;
}

.pink-a200 {
  color: #ff4081 !important;
}

.pink-a300 {
  color: #f50057 !important;
}

.pink-a400 {
  color: #c51162 !important;
}

.bg-purple-50 {
  background-color: #f3e5f5 !important;
}

.bg-purple-100 {
  background-color: #e1bee7 !important;
}

.bg-purple-200 {
  background-color: #ce93d8 !important;
}

.bg-purple-300 {
  background-color: #ba68c8 !important;
}

.bg-purple-400 {
  background-color: #ab47bc !important;
}

.bg-purple-500 {
  background-color: #9c27b0 !important;
}

.bg-purple-600 {
  background-color: #8e24aa !important;
}

.bg-purple-700 {
  background-color: #7b1fa2 !important;
}

.bg-purple-800 {
  background-color: #6a1b9a !important;
}

.bg-purple-900 {
  background-color: #4a148c !important;
}

.bg-purple-a100 {
  background-color: #ea80fc !important;
}

.bg-purple-a200 {
  background-color: #e040fb !important;
}

.bg-purple-a300 {
  background-color: #d500f9 !important;
}

.bg-purple-a400 {
  background-color: #aa00ff !important;
}

.purple-50 {
  color: #f3e5f5 !important;
}

.purple-100 {
  color: #e1bee7 !important;
}

.purple-200 {
  color: #ce93d8 !important;
}

.purple-300 {
  color: #ba68c8 !important;
}

.purple-400 {
  color: #ab47bc !important;
}

.purple-500 {
  color: #9c27b0 !important;
}

.purple-600 {
  color: #8e24aa !important;
}

.purple-700 {
  color: #7b1fa2 !important;
}

.purple-800 {
  color: #6a1b9a !important;
}

.purple-900 {
  color: #4a148c !important;
}

.purple-a100 {
  color: #ea80fc !important;
}

.purple-a200 {
  color: #e040fb !important;
}

.purple-a300 {
  color: #d500f9 !important;
}

.purple-a400 {
  color: #aa00ff !important;
}

.bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}

.bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.bg-deep-purple-200 {
  background-color: #b39ddb !important;
}

.bg-deep-purple-300 {
  background-color: #9575cd !important;
}

.bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}

.bg-deep-purple-500 {
  background-color: #673ab7 !important;
}

.bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}

.bg-deep-purple-700 {
  background-color: #512da8 !important;
}

.bg-deep-purple-800 {
  background-color: #4527a0 !important;
}

.bg-deep-purple-900 {
  background-color: #311b92 !important;
}

.bg-deep-purple-a100 {
  background-color: #b388ff !important;
}

.bg-deep-purple-a200 {
  background-color: #7c4dff !important;
}

.bg-deep-purple-a300 {
  background-color: #651fff !important;
}

.bg-deep-purple-a400 {
  background-color: #6200ea !important;
}

.deep-purple-50 {
  color: #ede7f6 !important;
}

.deep-purple-100 {
  color: #d1c4e9 !important;
}

.deep-purple-200 {
  color: #b39ddb !important;
}

.deep-purple-300 {
  color: #9575cd !important;
}

.deep-purple-400 {
  color: #7e57c2 !important;
}

.deep-purple-500 {
  color: #673ab7 !important;
}

.deep-purple-600 {
  color: #5e35b1 !important;
}

.deep-purple-700 {
  color: #512da8 !important;
}

.deep-purple-800 {
  color: #4527a0 !important;
}

.deep-purple-900 {
  color: #311b92 !important;
}

.deep-purple-a100 {
  color: #b388ff !important;
}

.deep-purple-a200 {
  color: #7c4dff !important;
}

.deep-purple-a300 {
  color: #651fff !important;
}

.deep-purple-a400 {
  color: #6200ea !important;
}

.bg-indigo-50 {
  background-color: #e8eaf6 !important;
}

.bg-indigo-100 {
  background-color: #c5cae9 !important;
}

.bg-indigo-200 {
  background-color: #9fa8da !important;
}

.bg-indigo-300 {
  background-color: #7986cb !important;
}

.bg-indigo-400 {
  background-color: #5c6bc0 !important;
}

.bg-indigo-500 {
  background-color: #3f51b5 !important;
}

.bg-indigo-600 {
  background-color: #3949ab !important;
}

.bg-indigo-700 {
  background-color: #303f9f !important;
}

.bg-indigo-800 {
  background-color: #283593 !important;
}

.bg-indigo-900 {
  background-color: #1a237e !important;
}

.bg-indigo-a100 {
  background-color: #8c9eff !important;
}

.bg-indigo-a200 {
  background-color: #536dfe !important;
}

.bg-indigo-a300 {
  background-color: #3d5afe !important;
}

.bg-indigo-a400 {
  background-color: #304ffe !important;
}

.indigo-50 {
  color: #e8eaf6 !important;
}

.indigo-100 {
  color: #c5cae9 !important;
}

.indigo-200 {
  color: #9fa8da !important;
}

.indigo-300 {
  color: #7986cb !important;
}

.indigo-400 {
  color: #5c6bc0 !important;
}

.indigo-500 {
  color: #3f51b5 !important;
}

.indigo-600 {
  color: #3949ab !important;
}

.indigo-700 {
  color: #303f9f !important;
}

.indigo-800 {
  color: #283593 !important;
}

.indigo-900 {
  color: #1a237e !important;
}

.indigo-a100 {
  color: #8c9eff !important;
}

.indigo-a200 {
  color: #536dfe !important;
}

.indigo-a300 {
  color: #3d5afe !important;
}

.indigo-a400 {
  color: #304ffe !important;
}

.bg-blue-50 {
  background-color: #e3f2fd !important;
}

.bg-blue-100 {
  background-color: #bbdefb !important;
}

.bg-blue-200 {
  background-color: #90caf9 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}

.bg-blue-400 {
  background-color: #42a5f5 !important;
}

.bg-blue-500 {
  background-color: #2196f3 !important;
}

.bg-blue-600 {
  background-color: #1e88e5 !important;
}

.bg-blue-700 {
  background-color: #1976d2 !important;
}

.bg-blue-800 {
  background-color: #1565c0 !important;
}

.bg-blue-900 {
  background-color: #0d47a1 !important;
}

.bg-blue-a100 {
  background-color: #82b1ff !important;
}

.bg-blue-a200 {
  background-color: #448aff !important;
}

.bg-blue-a300 {
  background-color: #2979ff !important;
}

.bg-blue-a400 {
  background-color: #2962ff !important;
}

.blue-50 {
  color: #e3f2fd !important;
}

.blue-100 {
  color: #bbdefb !important;
}

.blue-200 {
  color: #90caf9 !important;
}

.blue-300 {
  color: #64b5f6 !important;
}

.blue-400 {
  color: #42a5f5 !important;
}

.blue-500 {
  color: #2196f3 !important;
}

.blue-600 {
  color: #1e88e5 !important;
}

.blue-700 {
  color: #1976d2 !important;
}

.blue-800 {
  color: #1565c0 !important;
}

.blue-900 {
  color: #0d47a1 !important;
}

.blue-a100 {
  color: #82b1ff !important;
}

.blue-a200 {
  color: #448aff !important;
}

.blue-a300 {
  color: #2979ff !important;
}

.blue-a400 {
  color: #2962ff !important;
}

.bg-light-blue-50 {
  background-color: #e1f5fe !important;
}

.bg-light-blue-100 {
  background-color: #b3e5fc !important;
}

.bg-light-blue-200 {
  background-color: #81d4fa !important;
}

.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}

.bg-light-blue-400 {
  background-color: #29b6f6 !important;
}

.bg-light-blue-500 {
  background-color: #03a9f4 !important;
}

.bg-light-blue-600 {
  background-color: #039be5 !important;
}

.bg-light-blue-700 {
  background-color: #0288d1 !important;
}

.bg-light-blue-800 {
  background-color: #0277bd !important;
}

.bg-light-blue-900 {
  background-color: #01579b !important;
}

.bg-light-blue-a100 {
  background-color: #80d8ff !important;
}

.bg-light-blue-a200 {
  background-color: #40c4ff !important;
}

.bg-light-blue-a300 {
  background-color: #00b0ff !important;
}

.bg-light-blue-a400 {
  background-color: #0091ea !important;
}

.light-blue-50 {
  color: #e1f5fe !important;
}

.light-blue-100 {
  color: #b3e5fc !important;
}

.light-blue-200 {
  color: #81d4fa !important;
}

.light-blue-300 {
  color: #4fc3f7 !important;
}

.light-blue-400 {
  color: #29b6f6 !important;
}

.light-blue-500 {
  color: #03a9f4 !important;
}

.light-blue-600 {
  color: #039be5 !important;
}

.light-blue-700 {
  color: #0288d1 !important;
}

.light-blue-800 {
  color: #0277bd !important;
}

.light-blue-900 {
  color: #01579b !important;
}

.light-blue-a100 {
  color: #80d8ff !important;
}

.light-blue-a200 {
  color: #40c4ff !important;
}

.light-blue-a300 {
  color: #00b0ff !important;
}

.light-blue-a400 {
  color: #0091ea !important;
}

.bg-cyan-50 {
  background-color: #e0f7fa !important;
}

.bg-cyan-100 {
  background-color: #b2ebf2 !important;
}

.bg-cyan-200 {
  background-color: #80deea !important;
}

.bg-cyan-300 {
  background-color: #4dd0e1 !important;
}

.bg-cyan-400 {
  background-color: #26c6da !important;
}

.bg-cyan-500 {
  background-color: #00bcd4 !important;
}

.bg-cyan-600 {
  background-color: #00acc1 !important;
}

.bg-cyan-700 {
  background-color: #0097a7 !important;
}

.bg-cyan-800 {
  background-color: #00838f !important;
}

.bg-cyan-900 {
  background-color: #006064 !important;
}

.bg-cyan-a100 {
  background-color: #84ffff !important;
}

.bg-cyan-a200 {
  background-color: #18ffff !important;
}

.bg-cyan-a300 {
  background-color: #00e5ff !important;
}

.bg-cyan-a400 {
  background-color: #00b8d4 !important;
}

.cyan-50 {
  color: #e0f7fa !important;
}

.cyan-100 {
  color: #b2ebf2 !important;
}

.cyan-200 {
  color: #80deea !important;
}

.cyan-300 {
  color: #4dd0e1 !important;
}

.cyan-400 {
  color: #26c6da !important;
}

.cyan-500 {
  color: #00bcd4 !important;
}

.cyan-600 {
  color: #00acc1 !important;
}

.cyan-700 {
  color: #0097a7 !important;
}

.cyan-800 {
  color: #00838f !important;
}

.cyan-900 {
  color: #006064 !important;
}

.cyan-a100 {
  color: #84ffff !important;
}

.cyan-a200 {
  color: #18ffff !important;
}

.cyan-a300 {
  color: #00e5ff !important;
}

.cyan-a400 {
  color: #00b8d4 !important;
}

.bg-teal-50 {
  background-color: #e0f2f1 !important;
}

.bg-teal-100 {
  background-color: #b2dfdb !important;
}

.bg-teal-200 {
  background-color: #80cbc4 !important;
}

.bg-teal-300 {
  background-color: #4db6ac !important;
}

.bg-teal-400 {
  background-color: #26a69a !important;
}

.bg-teal-500 {
  background-color: #009688 !important;
}

.bg-teal-600 {
  background-color: #00897b !important;
}

.bg-teal-700 {
  background-color: #00796b !important;
}

.bg-teal-800 {
  background-color: #00695c !important;
}

.bg-teal-900 {
  background-color: #004d40 !important;
}

.bg-teal-a100 {
  background-color: #a7ffeb !important;
}

.bg-teal-a200 {
  background-color: #64ffda !important;
}

.bg-teal-a300 {
  background-color: #1de9b6 !important;
}

.bg-teal-a400 {
  background-color: #00bfa5 !important;
}

.teal-50 {
  color: #e0f2f1 !important;
}

.teal-100 {
  color: #b2dfdb !important;
}

.teal-200 {
  color: #80cbc4 !important;
}

.teal-300 {
  color: #4db6ac !important;
}

.teal-400 {
  color: #26a69a !important;
}

.teal-500 {
  color: #009688 !important;
}

.teal-600 {
  color: #00897b !important;
}

.teal-700 {
  color: #00796b !important;
}

.teal-800 {
  color: #00695c !important;
}

.teal-900 {
  color: #004d40 !important;
}

.teal-a100 {
  color: #a7ffeb !important;
}

.teal-a200 {
  color: #64ffda !important;
}

.teal-a300 {
  color: #1de9b6 !important;
}

.teal-a400 {
  color: #00bfa5 !important;
}

.bg-green-50 {
  background-color: #e8f5e9 !important;
}

.bg-green-100 {
  background-color: #c8e6c9 !important;
}

.bg-green-200 {
  background-color: #a5d6a7 !important;
}

.bg-green-300 {
  background-color: #81c784 !important;
}

.bg-green-400 {
  background-color: #66bb6a !important;
}

.bg-green-500 {
  background-color: #4caf50 !important;
}

.bg-green-600 {
  background-color: #43a047 !important;
}

.bg-green-700 {
  background-color: #388e3c !important;
}

.bg-green-800 {
  background-color: #2e7d32 !important;
}

.bg-green-900 {
  background-color: #1b5e20 !important;
}

.bg-green-a100 {
  background-color: #b9f6ca !important;
}

.bg-green-a200 {
  background-color: #69f0ae !important;
}

.bg-green-a300 {
  background-color: #00e676 !important;
}

.bg-green-a400 {
  background-color: #00c853 !important;
}

.green-50 {
  color: #e8f5e9 !important;
}

.green-100 {
  color: #c8e6c9 !important;
}

.green-200 {
  color: #a5d6a7 !important;
}

.green-300 {
  color: #81c784 !important;
}

.green-400 {
  color: #66bb6a !important;
}

.green-500 {
  color: #4caf50 !important;
}

.green-600 {
  color: #43a047 !important;
}

.green-700 {
  color: #388e3c !important;
}

.green-800 {
  color: #2e7d32 !important;
}

.green-900 {
  color: #1b5e20 !important;
}

.green-a100 {
  color: #b9f6ca !important;
}

.green-a200 {
  color: #69f0ae !important;
}

.green-a300 {
  color: #00e676 !important;
}

.green-a400 {
  color: #00c853 !important;
}

.bg-light-green-50 {
  background-color: #f1f8e9 !important;
}

.bg-light-green-100 {
  background-color: #dcedc8 !important;
}

.bg-light-green-200 {
  background-color: #c5e1a5 !important;
}

.bg-light-green-300 {
  background-color: #aed581 !important;
}

.bg-light-green-400 {
  background-color: #9ccc65 !important;
}

.bg-light-green-500 {
  background-color: #8bc34a !important;
}

.bg-light-green-600 {
  background-color: #7cb342 !important;
}

.bg-light-green-700 {
  background-color: #689f38 !important;
}

.bg-light-green-800 {
  background-color: #558b2f !important;
}

.bg-light-green-900 {
  background-color: #33691e !important;
}

.bg-light-green-a100 {
  background-color: #ccff90 !important;
}

.bg-light-green-a200 {
  background-color: #b2ff59 !important;
}

.bg-light-green-a300 {
  background-color: #76ff03 !important;
}

.bg-light-green-a400 {
  background-color: #64dd17 !important;
}

.light-green-50 {
  color: #f1f8e9 !important;
}

.light-green-100 {
  color: #dcedc8 !important;
}

.light-green-200 {
  color: #c5e1a5 !important;
}

.light-green-300 {
  color: #aed581 !important;
}

.light-green-400 {
  color: #9ccc65 !important;
}

.light-green-500 {
  color: #8bc34a !important;
}

.light-green-600 {
  color: #7cb342 !important;
}

.light-green-700 {
  color: #689f38 !important;
}

.light-green-800 {
  color: #558b2f !important;
}

.light-green-900 {
  color: #33691e !important;
}

.light-green-a100 {
  color: #ccff90 !important;
}

.light-green-a200 {
  color: #b2ff59 !important;
}

.light-green-a300 {
  color: #76ff03 !important;
}

.light-green-a400 {
  color: #64dd17 !important;
}

.bg-lime-50 {
  background-color: #f9fbe7 !important;
}

.bg-lime-100 {
  background-color: #f0f4c3 !important;
}

.bg-lime-200 {
  background-color: #e6ee9c !important;
}

.bg-lime-300 {
  background-color: #dce775 !important;
}

.bg-lime-400 {
  background-color: #d4e157 !important;
}

.bg-lime-500 {
  background-color: #cddc39 !important;
}

.bg-lime-600 {
  background-color: #c0ca33 !important;
}

.bg-lime-700 {
  background-color: #afb42b !important;
}

.bg-lime-800 {
  background-color: #9e9d24 !important;
}

.bg-lime-900 {
  background-color: #827717 !important;
}

.bg-lime-a100 {
  background-color: #f4ff81 !important;
}

.bg-lime-a200 {
  background-color: #eeff41 !important;
}

.bg-lime-a300 {
  background-color: #c6ff00 !important;
}

.bg-lime-a400 {
  background-color: #aeea00 !important;
}

.lime-50 {
  color: #f9fbe7 !important;
}

.lime-100 {
  color: #f0f4c3 !important;
}

.lime-200 {
  color: #e6ee9c !important;
}

.lime-300 {
  color: #dce775 !important;
}

.lime-400 {
  color: #d4e157 !important;
}

.lime-500 {
  color: #cddc39 !important;
}

.lime-600 {
  color: #c0ca33 !important;
}

.lime-700 {
  color: #afb42b !important;
}

.lime-800 {
  color: #9e9d24 !important;
}

.lime-900 {
  color: #827717 !important;
}

.lime-a100 {
  color: #f4ff81 !important;
}

.lime-a200 {
  color: #eeff41 !important;
}

.lime-a300 {
  color: #c6ff00 !important;
}

.lime-a400 {
  color: #aeea00 !important;
}

.bg-yellow-50 {
  background-color: #fffde7 !important;
}

.bg-yellow-100 {
  background-color: #fff9c4 !important;
}

.bg-yellow-200 {
  background-color: #fff59d !important;
}

.bg-yellow-300 {
  background-color: #fff176 !important;
}

.bg-yellow-400 {
  background-color: #ffee58 !important;
}

.bg-yellow-500 {
  background-color: #ffeb3b !important;
}

.bg-yellow-600 {
  background-color: #fdd835 !important;
}

.bg-yellow-700 {
  background-color: #fbc02d !important;
}

.bg-yellow-800 {
  background-color: #f9a825 !important;
}

.bg-yellow-900 {
  background-color: #f57f17 !important;
}

.bg-yellow-a100 {
  background-color: #ffff8d !important;
}

.bg-yellow-a200 {
  background-color: #ffff00 !important;
}

.bg-yellow-a300 {
  background-color: #ffea00 !important;
}

.bg-yellow-a400 {
  background-color: #ffd600 !important;
}

.yellow-50 {
  color: #fffde7 !important;
}

.yellow-100 {
  color: #fff9c4 !important;
}

.yellow-200 {
  color: #fff59d !important;
}

.yellow-300 {
  color: #fff176 !important;
}

.yellow-400 {
  color: #ffee58 !important;
}

.yellow-500 {
  color: #ffeb3b !important;
}

.yellow-600 {
  color: #fdd835 !important;
}

.yellow-700 {
  color: #fbc02d !important;
}

.yellow-800 {
  color: #f9a825 !important;
}

.yellow-900 {
  color: #f57f17 !important;
}

.yellow-a100 {
  color: #ffff8d !important;
}

.yellow-a200 {
  color: #ffff00 !important;
}

.yellow-a300 {
  color: #ffea00 !important;
}

.yellow-a400 {
  color: #ffd600 !important;
}

.bg-amber-50 {
  background-color: #fff8e1 !important;
}

.bg-amber-100 {
  background-color: #ffecb3 !important;
}

.bg-amber-200 {
  background-color: #ffe082 !important;
}

.bg-amber-300 {
  background-color: #ffd54f !important;
}

.bg-amber-400 {
  background-color: #ffca28 !important;
}

.bg-amber-500 {
  background-color: #ffc107 !important;
}

.bg-amber-600 {
  background-color: #ffb300 !important;
}

.bg-amber-700 {
  background-color: #ffa000 !important;
}

.bg-amber-800 {
  background-color: #ff8f00 !important;
}

.bg-amber-900 {
  background-color: #ff6f00 !important;
}

.bg-amber-a100 {
  background-color: #ffe57f !important;
}

.bg-amber-a200 {
  background-color: #ffd740 !important;
}

.bg-amber-a300 {
  background-color: #ffc400 !important;
}

.bg-amber-a400 {
  background-color: #ffab00 !important;
}

.amber-50 {
  color: #fff8e1 !important;
}

.amber-100 {
  color: #ffecb3 !important;
}

.amber-200 {
  color: #ffe082 !important;
}

.amber-300 {
  color: #ffd54f !important;
}

.amber-400 {
  color: #ffca28 !important;
}

.amber-500 {
  color: #ffc107 !important;
}

.amber-600 {
  color: #ffb300 !important;
}

.amber-700 {
  color: #ffa000 !important;
}

.amber-800 {
  color: #ff8f00 !important;
}

.amber-900 {
  color: #ff6f00 !important;
}

.amber-a100 {
  color: #ffe57f !important;
}

.amber-a200 {
  color: #ffd740 !important;
}

.amber-a300 {
  color: #ffc400 !important;
}

.amber-a400 {
  color: #ffab00 !important;
}

.bg-orange-50 {
  background-color: #fff3e0 !important;
}

.bg-orange-100 {
  background-color: #ffe0b2 !important;
}

.bg-orange-200 {
  background-color: #ffcc80 !important;
}

.bg-orange-300 {
  background-color: #ffb74d !important;
}

.bg-orange-400 {
  background-color: #ffa726 !important;
}

.bg-orange-500 {
  background-color: #ff9800 !important;
}

.bg-orange-600 {
  background-color: #fb8c00 !important;
}

.bg-orange-700 {
  background-color: #f57c00 !important;
}

.bg-orange-800 {
  background-color: #ef6c00 !important;
}

.bg-orange-900 {
  background-color: #e65100 !important;
}

.bg-orange-a100 {
  background-color: #ffd180 !important;
}

.bg-orange-a200 {
  background-color: #ffab40 !important;
}

.bg-orange-a300 {
  background-color: #ff9100 !important;
}

.bg-orange-a400 {
  background-color: #ff6d00 !important;
}

.orange-50 {
  color: #fff3e0 !important;
}

.orange-100 {
  color: #ffe0b2 !important;
}

.orange-200 {
  color: #ffcc80 !important;
}

.orange-300 {
  color: #ffb74d !important;
}

.orange-400 {
  color: #ffa726 !important;
}

.orange-500 {
  color: #ff9800 !important;
}

.orange-600 {
  color: #fb8c00 !important;
}

.orange-700 {
  color: #f57c00 !important;
}

.orange-800 {
  color: #ef6c00 !important;
}

.orange-900 {
  color: #e65100 !important;
}

.orange-a100 {
  color: #ffd180 !important;
}

.orange-a200 {
  color: #ffab40 !important;
}

.orange-a300 {
  color: #ff9100 !important;
}

.orange-a400 {
  color: #ff6d00 !important;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.bg-deep-orange-100 {
  background-color: #ffccbc !important;
}

.bg-deep-orange-200 {
  background-color: #ffab91 !important;
}

.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}

.bg-deep-orange-400 {
  background-color: #ff7043 !important;
}

.bg-deep-orange-500 {
  background-color: #ff5722 !important;
}

.bg-deep-orange-600 {
  background-color: #f4511e !important;
}

.bg-deep-orange-700 {
  background-color: #e64a19 !important;
}

.bg-deep-orange-800 {
  background-color: #d84315 !important;
}

.bg-deep-orange-900 {
  background-color: #bf360c !important;
}

.bg-deep-orange-a100 {
  background-color: #ff9e80 !important;
}

.bg-deep-orange-a200 {
  background-color: #ff6e40 !important;
}

.bg-deep-orange-a300 {
  background-color: #ff3d00 !important;
}

.bg-deep-orange-a400 {
  background-color: #dd2c00 !important;
}

.deep-orange-50 {
  color: #fbe9e7 !important;
}

.deep-orange-100 {
  color: #ffccbc !important;
}

.deep-orange-200 {
  color: #ffab91 !important;
}

.deep-orange-300 {
  color: #ff8a65 !important;
}

.deep-orange-400 {
  color: #ff7043 !important;
}

.deep-orange-500 {
  color: #ff5722 !important;
}

.deep-orange-600 {
  color: #f4511e !important;
}

.deep-orange-700 {
  color: #e64a19 !important;
}

.deep-orange-800 {
  color: #d84315 !important;
}

.deep-orange-900 {
  color: #bf360c !important;
}

.deep-orange-a100 {
  color: #ff9e80 !important;
}

.deep-orange-a200 {
  color: #ff6e40 !important;
}

.deep-orange-a300 {
  color: #ff3d00 !important;
}

.deep-orange-a400 {
  color: #dd2c00 !important;
}

.bg-brown-50 {
  background-color: #efebe9 !important;
}

.bg-brown-100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-300 {
  background-color: #a1887f !important;
}

.bg-brown-400 {
  background-color: #8d6e63 !important;
}

.bg-brown-500 {
  background-color: #795548 !important;
}

.bg-brown-600 {
  background-color: #6d4c41 !important;
}

.bg-brown-700 {
  background-color: #5d4037 !important;
}

.bg-brown-800 {
  background-color: #4e342e !important;
}

.bg-brown-900 {
  background-color: #3e2723 !important;
}

.brown-50 {
  color: #efebe9 !important;
}

.brown-100 {
  color: #d7ccc8 !important;
}

.brown-200 {
  color: #bcaaa4 !important;
}

.brown-300 {
  color: #a1887f !important;
}

.brown-400 {
  color: #8d6e63 !important;
}

.brown-500 {
  color: #795548 !important;
}

.brown-600 {
  color: #6d4c41 !important;
}

.brown-700 {
  color: #5d4037 !important;
}

.brown-800 {
  color: #4e342e !important;
}

.brown-900 {
  color: #3e2723 !important;
}

.bg-grey-50 {
  background-color: #fafafa !important;
}

.bg-grey-100 {
  background-color: #f5f5f5 !important;
}

.bg-grey-200 {
  background-color: #eeeeee !important;
}

.bg-grey-300 {
  background-color: #e0e0e0 !important;
}

.bg-grey-400 {
  background-color: #bdbdbd !important;
}

.bg-grey-500 {
  background-color: #9e9e9e !important;
}

.bg-grey-600 {
  background-color: #757575 !important;
}

.bg-grey-700 {
  background-color: #616161 !important;
}

.bg-grey-800 {
  background-color: #424242 !important;
}

.bg-grey-900 {
  background-color: #212121 !important;
}

.grey-50 {
  color: #fafafa !important;
}

.grey-100 {
  color: #f5f5f5 !important;
}

.grey-200 {
  color: #eeeeee !important;
}

.grey-300 {
  color: #e0e0e0 !important;
}

.grey-400 {
  color: #bdbdbd !important;
}

.grey-500 {
  color: #9e9e9e !important;
}

.grey-600 {
  color: #757575 !important;
}

.grey-700 {
  color: #616161 !important;
}

.grey-800 {
  color: #424242 !important;
}

.grey-900 {
  color: #212121 !important;
}

.bg-blue-grey-50 {
  background-color: #eceff1 !important;
}

.bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}

.bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}

.bg-blue-grey-300 {
  background-color: #90a4ae !important;
}

.bg-blue-grey-400 {
  background-color: #78909c !important;
}

.bg-blue-grey-500 {
  background-color: #607d8b !important;
}

.bg-blue-grey-600 {
  background-color: #546e7a !important;
}

.bg-blue-grey-700 {
  background-color: #455a64 !important;
}

.bg-blue-grey-800 {
  background-color: #37474f !important;
}

.bg-blue-grey-900 {
  background-color: #263238 !important;
}

.blue-grey-50 {
  color: #eceff1 !important;
}

.blue-grey-100 {
  color: #cfd8dc !important;
}

.blue-grey-200 {
  color: #b0bec5 !important;
}

.blue-grey-300 {
  color: #90a4ae !important;
}

.blue-grey-400 {
  color: #78909c !important;
}

.blue-grey-500 {
  color: #607d8b !important;
}

.blue-grey-600 {
  color: #546e7a !important;
}

.blue-grey-700 {
  color: #455a64 !important;
}

.blue-grey-800 {
  color: #37474f !important;
}

.blue-grey-900 {
  color: #263238 !important;
}

.bg-primary-50 {
  background-color: #e8eaf6 !important;
}

.bg-primary-100 {
  background-color: #c5cae9 !important;
}

.bg-primary-200 {
  background-color: #9fa8da !important;
}

.bg-primary-300 {
  background-color: #7986cb !important;
}

.bg-primary-400 {
  background-color: #5c6bc0 !important;
}

.bg-primary-500 {
  background-color: #3f51b5 !important;
}

.bg-primary-600 {
  background-color: #3949ab !important;
}

.bg-primary-700 {
  background-color: #303f9f !important;
}

.bg-primary-800 {
  background-color: #283593 !important;
}

.bg-primary-900 {
  background-color: #1a237e !important;
}

.primary-50 {
  color: #e8eaf6 !important;
}

.primary-100 {
  color: #c5cae9 !important;
}

.primary-200 {
  color: #9fa8da !important;
}

.primary-300 {
  color: #7986cb !important;
}

.primary-400 {
  color: #5c6bc0 !important;
}

.primary-500 {
  color: #3f51b5 !important;
}

.primary-600 {
  color: #3949ab !important;
}

.primary-700 {
  color: #303f9f !important;
}

.primary-800 {
  color: #283593 !important;
}

.primary-900 {
  color: #1a237e !important;
}

.black {
  color: #000000 !important;
}

.white {
  color: #ffffff !important;
}

.bg-white {
  color: #757575;
  background-color: #ffffff;
}

.bg-primary {
  color: #ffffff;
  background-color: #3f51b5;
}

.bg-primary:hover {
  background-color: #606fc7;
}

.bg-primary a,
.bg-primary a.bg-primary {
  color: #ffffff;
}

.bg-primary a:hover,
.bg-primary a.bg-primary:hover {
  color: #ffffff;
}

.bg-success {
  color: #ffffff;
  background-color: #4caf50;
}

.bg-success:hover {
  background-color: #6ec071;
}

.bg-success a,
.bg-success a.bg-primary {
  color: #ffffff;
}

.bg-success a:hover,
.bg-success a.bg-primary:hover {
  color: #ffffff;
}

.bg-info {
  color: #ffffff;
  background-color: #00bcd4;
}

.bg-info:hover {
  background-color: #08e3ff;
}

.bg-info a,
.bg-info a.bg-info {
  color: #ffffff;
}

.bg-info a:hover,
.bg-info a.bg-info:hover {
  color: #ffffff;
}

.bg-warning {
  color: #ffffff;
  background-color: #ff9800;
}

.bg-warning:hover {
  background-color: #ffad33;
}

.bg-warning a,
.bg-warning a.bg-warning {
  color: #ffffff;
}

.bg-warning a:hover,
.bg-warning a.bg-warning:hover {
  color: #ffffff;
}

.bg-danger {
  color: #ffffff;
  background-color: #f44336;
}

.bg-danger:hover {
  background-color: #f77066;
}

.bg-danger a,
.bg-danger a.bg-danger {
  color: #ffffff;
}

.bg-danger a:hover,
.bg-danger a.bg-danger:hover {
  color: #ffffff;
}

.bg-dark {
  color: #ffffff;
  background-color: #616161;
}

.bg-dark:hover {
  background-color: #7b7b7b;
}

.bg-dark a,
.bg-dark a.bg-dark {
  color: #ffffff;
}

.bg-dark a:hover,
.bg-dark a.bg-dark:hover {
  color: #ffffff;
}

.social-facebook {
  color: #ffffff;
  background-color: #3B5998 !important;
}

.social-facebook:hover, .social-facebook:focus {
  color: #ffffff;
  background-color: #4c70ba !important;
}

.social-facebook:active, .social-facebook.active {
  color: #ffffff;
  background-color: #2d4373 !important;
}

.bg-facebook {
  background-color: #3B5998;
}

.social-twitter {
  color: #ffffff;
  background-color: #55ACEE !important;
}

.social-twitter:hover, .social-twitter:focus {
  color: #ffffff;
  background-color: #83c3f3 !important;
}

.social-twitter:active, .social-twitter.active {
  color: #ffffff;
  background-color: #2795e9 !important;
}

.bg-twitter {
  background-color: #55ACEE;
}

.social-google-plus {
  color: #ffffff;
  background-color: #DD4B39 !important;
}

.social-google-plus:hover, .social-google-plus:focus {
  color: #ffffff;
  background-color: #e47365 !important;
}

.social-google-plus:active, .social-google-plus.active {
  color: #ffffff;
  background-color: #c23321 !important;
}

.bg-google-plus {
  background-color: #DD4B39;
}

.social-linkedin {
  color: #ffffff;
  background-color: #0976B4 !important;
}

.social-linkedin:hover, .social-linkedin:focus {
  color: #ffffff;
  background-color: #0b96e5 !important;
}

.social-linkedin:active, .social-linkedin.active {
  color: #ffffff;
  background-color: #075683 !important;
}

.bg-linkedin {
  background-color: #0976B4;
}

.social-flickr {
  color: #ffffff;
  background-color: #FF0084 !important;
}

.social-flickr:hover, .social-flickr:focus {
  color: #ffffff;
  background-color: #ff339d !important;
}

.social-flickr:active, .social-flickr.active {
  color: #ffffff;
  background-color: #cc006a !important;
}

.bg-flickr {
  background-color: #FF0084;
}

.social-tumblr {
  color: #ffffff;
  background-color: #35465C !important;
}

.social-tumblr:hover, .social-tumblr:focus {
  color: #ffffff;
  background-color: #485f7c !important;
}

.social-tumblr:active, .social-tumblr.active {
  color: #ffffff;
  background-color: #222d3c !important;
}

.bg-tumblr {
  background-color: #35465C;
}

.social-xing {
  color: #ffffff;
  background-color: #024b4d !important;
}

.social-xing:hover, .social-xing:focus {
  color: #ffffff;
  background-color: #037b7f !important;
}

.social-xing:active, .social-xing.active {
  color: #ffffff;
  background-color: #011b1b !important;
}

.bg-xing {
  background-color: #024b4d;
}

.social-github {
  color: #ffffff;
  background-color: #4183c4 !important;
}

.social-github:hover, .social-github:focus {
  color: #ffffff;
  background-color: #689cd0 !important;
}

.social-github:active, .social-github.active {
  color: #ffffff;
  background-color: #3269a0 !important;
}

.bg-github {
  background-color: #4183c4;
}

.social-html5 {
  color: #ffffff;
  background-color: #E44F26 !important;
}

.social-html5:hover, .social-html5:focus {
  color: #ffffff;
  background-color: #ea7453 !important;
}

.social-html5:active, .social-html5.active {
  color: #ffffff;
  background-color: #bf3c18 !important;
}

.bg-html5 {
  background-color: #E44F26;
}

.social-openid {
  color: #ffffff;
  background-color: #f67d28 !important;
}

.social-openid:hover, .social-openid:focus {
  color: #ffffff;
  background-color: #f89b59 !important;
}

.social-openid:active, .social-openid.active {
  color: #ffffff;
  background-color: #e26309 !important;
}

.bg-openid {
  background-color: #f67d28;
}

.social-stack-overflow {
  color: #ffffff;
  background-color: #f86c01 !important;
}

.social-stack-overflow:hover, .social-stack-overflow:focus {
  color: #ffffff;
  background-color: #fe882e !important;
}

.social-stack-overflow:active, .social-stack-overflow.active {
  color: #ffffff;
  background-color: #c55601 !important;
}

.bg-stack-overflow {
  background-color: #f86c01;
}

.social-css3 {
  color: #ffffff;
  background-color: #1572B6 !important;
}

.social-css3:hover, .social-css3:focus {
  color: #ffffff;
  background-color: #1a8fe4 !important;
}

.social-css3:active, .social-css3.active {
  color: #ffffff;
  background-color: #105588 !important;
}

.bg-css3 {
  background-color: #1572B6;
}

.social-youtube {
  color: #ffffff;
  background-color: #B31217 !important;
}

.social-youtube:hover, .social-youtube:focus {
  color: #ffffff;
  background-color: #e1171d !important;
}

.social-youtube:active, .social-youtube.active {
  color: #ffffff;
  background-color: #850d11 !important;
}

.bg-youtube {
  background-color: #B31217;
}

.social-dribbble {
  color: #ffffff;
  background-color: #C32361 !important;
}

.social-dribbble:hover, .social-dribbble:focus {
  color: #ffffff;
  background-color: #dc3d7b !important;
}

.social-dribbble:active, .social-dribbble.active {
  color: #ffffff;
  background-color: #981b4b !important;
}

.bg-dribbble {
  background-color: #C32361;
}

.social-instagram {
  color: #ffffff;
  background-color: #3F729B !important;
}

.social-instagram:hover, .social-instagram:focus {
  color: #ffffff;
  background-color: #548cb9 !important;
}

.social-instagram:active, .social-instagram.active {
  color: #ffffff;
  background-color: #305777 !important;
}

.bg-instagram {
  background-color: #3F729B;
}

.social-pinterest {
  color: #ffffff;
  background-color: #CC2127 !important;
}

.social-pinterest:hover, .social-pinterest:focus {
  color: #ffffff;
  background-color: #e04046 !important;
}

.social-pinterest:active, .social-pinterest.active {
  color: #ffffff;
  background-color: #a01a1f !important;
}

.bg-pinterest {
  background-color: #CC2127;
}

.social-vk {
  color: #ffffff;
  background-color: #3d5a7d !important;
}

.social-vk:hover, .social-vk:focus {
  color: #ffffff;
  background-color: #4e739f !important;
}

.social-vk:active, .social-vk.active {
  color: #ffffff;
  background-color: #2c415b !important;
}

.bg-vk {
  background-color: #3d5a7d;
}

.social-yahoo {
  color: #ffffff;
  background-color: #350178 !important;
}

.social-yahoo:hover, .social-yahoo:focus {
  color: #ffffff;
  background-color: #4b01ab !important;
}

.social-yahoo:active, .social-yahoo.active {
  color: #ffffff;
  background-color: #1f0145 !important;
}

.bg-yahoo {
  background-color: #350178;
}

.social-behance {
  color: #ffffff;
  background-color: #1769FF !important;
}

.social-behance:hover, .social-behance:focus {
  color: #ffffff;
  background-color: #4a8aff !important;
}

.social-behance:active, .social-behance.active {
  color: #ffffff;
  background-color: #0050e3 !important;
}

.bg-behance {
  background-color: #024b4d;
}

.social-dropbox {
  color: #ffffff;
  background-color: #007EE5 !important;
}

.social-dropbox:hover, .social-dropbox:focus {
  color: #ffffff;
  background-color: #1998ff !important;
}

.social-dropbox:active, .social-dropbox.active {
  color: #ffffff;
  background-color: #0062b2 !important;
}

.bg-dropbox {
  background-color: #007EE5;
}

.social-reddit {
  color: #ffffff;
  background-color: #FF4500 !important;
}

.social-reddit:hover, .social-reddit:focus {
  color: #ffffff;
  background-color: #ff6a33 !important;
}

.social-reddit:active, .social-reddit.active {
  color: #ffffff;
  background-color: #cc3700 !important;
}

.bg-reddit {
  background-color: #FF4500;
}

.social-spotify {
  color: #ffffff;
  background-color: #7AB800 !important;
}

.social-spotify:hover, .social-spotify:focus {
  color: #ffffff;
  background-color: #9ceb00 !important;
}

.social-spotify:active, .social-spotify.active {
  color: #ffffff;
  background-color: #588500 !important;
}

.bg-spotify {
  background-color: #7AB800;
}

.social-vine {
  color: #ffffff;
  background-color: #00B488 !important;
}

.social-vine:hover, .social-vine:focus {
  color: #ffffff;
  background-color: #00e7af !important;
}

.social-vine:active, .social-vine.active {
  color: #ffffff;
  background-color: #008161 !important;
}

.bg-vine {
  background-color: #00B488;
}

.social-foursquare {
  color: #ffffff;
  background-color: #0cbadf !important;
}

.social-foursquare:hover, .social-foursquare:focus {
  color: #ffffff;
  background-color: #2ad0f4 !important;
}

.social-foursquare:active, .social-foursquare.active {
  color: #ffffff;
  background-color: #0992af !important;
}

.bg-foursquare {
  background-color: #0cbadf;
}

.social-vimeo {
  color: #ffffff;
  background-color: #1AB7EA !important;
}

.social-vimeo:hover, .social-vimeo:focus {
  color: #ffffff;
  background-color: #49c6ee !important;
}

.social-vimeo:active, .social-vimeo.active {
  color: #ffffff;
  background-color: #1295bf !important;
}

.bg-vimeo {
  background-color: #1AB7EA;
}

.social-skype {
  color: #ffffff;
  background-color: #77bcfd !important;
}

.social-skype:hover, .social-skype:focus {
  color: #ffffff;
  background-color: #a9d5fe !important;
}

.social-skype:active, .social-skype.active {
  color: #ffffff;
  background-color: #45a3fc !important;
}

.bg-skype {
  background-color: #77bcfd;
}

.social-evernote {
  color: #ffffff;
  background-color: #46bf8c !important;
}

.social-evernote:hover, .social-evernote:focus {
  color: #ffffff;
  background-color: #6ccca4 !important;
}

.social-evernote:active, .social-evernote.active {
  color: #ffffff;
  background-color: #369c71 !important;
}

.bg-evernote {
  background-color: #46bf8c;
}

.blocks,
[class*="blocks-"] {
  font-size: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.blocks > .block,
[class*="blocks-"] > li,
[class*="blocks-"] > .block {
  display: inline-block;
  font-size: 1rem;
  vertical-align: top;
  margin-bottom: 1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.blocks.no-space,
[class*="blocks-"].no-space {
  margin: 0;
}

.blocks.no-space > li,
.blocks.no-space > .block,
[class*="blocks-"].no-space > li,
[class*="blocks-"].no-space > .block {
  padding: 0;
  margin: 0;
}

.blocks-100 > .block,
.blocks-100 > li {
  width: 100%;
}

.blocks-2 > .block,
.blocks-2 > li {
  width: 50%;
}

.blocks-3 > .block,
.blocks-3 > li {
  width: 33.333333%;
}

.blocks-4 > .block,
.blocks-4 > li {
  width: 25%;
}

.blocks-5 > .block,
.blocks-5 > li {
  width: 20%;
}

.blocks-6 > .block,
.blocks-6 > li {
  width: 16.666667%;
}

.blocks-xs-100 > .block,
.blocks-xs-100 > li {
  width: 100%;
}

.blocks-xs-2 > .block,
.blocks-xs-2 > li {
  width: 50%;
}

.blocks-xs-3 > .block,
.blocks-xs-3 > li {
  width: 33.333333%;
}

.blocks-xs-4 > .block,
.blocks-xs-4 > li {
  width: 25%;
}

.blocks-xs-5 > .block,
.blocks-xs-5 > li {
  width: 20%;
}

.blocks-xs-6 > .block,
.blocks-xs-6 > li {
  width: 16.666667%;
}

@media (min-width: 480px) {
  .blocks-sm-100 > .block,
  .blocks-sm-100 > li {
    width: 100%;
  }
  .blocks-sm-2 > .block,
  .blocks-sm-2 > li {
    width: 50%;
  }
  .blocks-sm-3 > .block,
  .blocks-sm-3 > li {
    width: 33.333333%;
  }
  .blocks-sm-4 > .block,
  .blocks-sm-4 > li {
    width: 25%;
  }
  .blocks-sm-5 > .block,
  .blocks-sm-5 > li {
    width: 20%;
  }
  .blocks-sm-6 > .block,
  .blocks-sm-6 > li {
    width: 16.666667%;
  }
}

@media (min-width: 768px) {
  .blocks-md-100 > .block,
  .blocks-md-100 > li {
    width: 100%;
  }
  .blocks-md-2 > .block,
  .blocks-md-2 > li {
    width: 50%;
  }
  .blocks-md-3 > .block,
  .blocks-md-3 > li {
    width: 33.333333%;
  }
  .blocks-md-4 > .block,
  .blocks-md-4 > li {
    width: 25%;
  }
  .blocks-md-5 > .block,
  .blocks-md-5 > li {
    width: 20%;
  }
  .blocks-md-6 > .block,
  .blocks-md-6 > li {
    width: 16.666667%;
  }
}

@media (min-width: 992px) {
  .blocks-lg-100 > .block,
  .blocks-lg-100 > li {
    width: 100%;
  }
  .blocks-lg-2 > .block,
  .blocks-lg-2 > li {
    width: 50%;
  }
  .blocks-lg-3 > .block,
  .blocks-lg-3 > li {
    width: 33.333333%;
  }
  .blocks-lg-4 > .block,
  .blocks-lg-4 > li {
    width: 25%;
  }
  .blocks-lg-5 > .block,
  .blocks-lg-5 > li {
    width: 20%;
  }
  .blocks-lg-6 > .block,
  .blocks-lg-6 > li {
    width: 16.666667%;
  }
}

@media (min-width: 1200px) {
  .blocks-xl-100 > .block,
  .blocks-xl-100 > li {
    width: 100%;
  }
  .blocks-xl-2 > .block,
  .blocks-xl-2 > li {
    width: 50%;
  }
  .blocks-xl-3 > .block,
  .blocks-xl-3 > li {
    width: 33.333333%;
  }
  .blocks-xl-4 > .block,
  .blocks-xl-4 > li {
    width: 25%;
  }
  .blocks-xl-5 > .block,
  .blocks-xl-5 > li {
    width: 20%;
  }
  .blocks-xl-6 > .block,
  .blocks-xl-6 > li {
    width: 16.666667%;
  }
}

@media (min-width: 1600px) {
  .blocks-xxl-100 > .block,
  .blocks-xxl-100 > li {
    width: 100%;
  }
  .blocks-xxl-2 > .block,
  .blocks-xxl-2 > li {
    width: 50%;
  }
  .blocks-xxl-3 > .block,
  .blocks-xxl-3 > li {
    width: 33.333333%;
  }
  .blocks-xxl-4 > .block,
  .blocks-xxl-4 > li {
    width: 25%;
  }
  .blocks-xxl-5 > .block,
  .blocks-xxl-5 > li {
    width: 20%;
  }
  .blocks-xxl-6 > .block,
  .blocks-xxl-6 > li {
    width: 16.666667%;
  }
}

.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom;
}

.avatar i {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}

.avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0 none;
  border-radius: 1000px;
}

.avatar-online i {
  background-color: #4caf50;
}

.avatar-off i {
  background-color: #616161;
}

.avatar-busy i {
  background-color: #ff9800;
}

.avatar-away i {
  background-color: #f44336;
}

.avatar-100 {
  width: 100px;
}

.avatar-100 i {
  height: 20px;
  width: 20px;
}

.avatar-lg {
  width: 50px;
}

.avatar-lg i {
  height: 12px;
  width: 12px;
}

.avatar-sm {
  width: 30px;
}

.avatar-sm i {
  height: 8px;
  width: 8px;
}

.avatar-xs {
  width: 20px;
}

.avatar-xs i {
  height: 7px;
  width: 7px;
}

.status {
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}

.status-online {
  background-color: #4caf50;
}

.status-off {
  background-color: #616161;
}

.status-busy {
  background-color: #ff9800;
}

.status-away {
  background-color: #f44336;
}

.status-lg {
  width: 14px;
  height: 14px;
}

.status-md {
  width: 10px;
  height: 10px;
}

.status-sm {
  width: 8px;
  height: 8px;
}

.status-xs {
  width: 7px;
  height: 7px;
}

.icon {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 1;
}

.icon.float-left {
  margin-right: .3em;
}

.icon.float-right {
  margin-left: .3em;
}

.icon-circle {
  position: relative;
  margin: .5em;
}

.icon-circle:before {
  position: relative;
  z-index: 1;
}

.icon-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  content: "";
  width: 2em;
  height: 2em;
  background-color: inherit;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.icon-lg {
  font-size: 1.333333em;
  vertical-align: -15%;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-fw {
  width: 1.285714em;
  text-align: center;
}

.icon-ul {
  padding-left: 0;
  margin-left: 2.142857em;
  list-style-type: none;
}

.icon-ul > li {
  position: relative;
}

.icon-li {
  position: absolute;
  top: 0.142857em;
  left: -2.142857em;
  width: 2.142857em;
  text-align: center;
}

.icon-li.icon-lg {
  left: -1.857143em;
}

.icon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #e0e0e0;
  border-radius: .1em;
}

.icon-spin {
  animation: icon-spin 2s infinite linear;
}

.icon-spin-reverse {
  animation: icon-spin-reverse 2s infinite linear;
}

.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.icon-stack-1x, .icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.icon-stack-1x {
  line-height: inherit;
}

.icon-stack-2x {
  font-size: 2em;
}

.icon-stack-inverse {
  color: #ffffff;
}

.icon-color {
  color: rgba(66, 66, 66, 0.4);
}

.icon-color:hover, .icon-color:focus {
  color: rgba(66, 66, 66, 0.6);
}

.icon-color.active, .icon-color:active {
  color: #424242;
}

.icon-color-alt {
  color: rgba(66, 66, 66, 0.6);
}

.icon-color-alt:hover, .icon-color-alt:focus {
  color: rgba(66, 66, 66, 0.8);
}

.icon-color-alt.active, .icon-color-alt:active {
  color: #424242;
}

:root-rotate-90, :root-rotate-180, :root-rotate-270, :root-flip-horizontal, :root-flip-vertical {
  filter: none;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.hamburger {
  vertical-align: middle;
  font-size: 17px;
}

.hamburger, .hamburger:before,
.hamburger .hamburger-bar, .hamburger:after {
  transition: transform .2s ease-in-out;
}

.hamburger:before, .hamburger:after {
  content: "";
}

.hamburger:before,
.hamburger .hamburger-bar, .hamburger:after {
  display: block;
  width: 1em;
  height: 0.1em;
  margin: 0;
  border-radius: 1px;
  background: #757575;
}

.navbar-default .hamburger:before, .navbar-default
.hamburger .hamburger-bar, .navbar-default .hamburger:after {
  background: #757575;
}

.navbar-inverse .hamburger:before, .navbar-inverse
.hamburger .hamburger-bar, .navbar-inverse .hamburger:after {
  background: #ffffff;
}

.hamburger .hamburger-bar {
  margin: 0.2em 0;
}

.hamburger-close:before {
  transform: rotate(45deg);
  transform-origin: 8%;
}

.hamburger-close .hamburger-bar {
  opacity: 0;
}

.hamburger-close:after {
  transform: rotate(-45deg);
  transform-origin: 8%;
}

.hamburger-close.hided:before, .hamburger-close.collapsed:before {
  transform: rotate(0);
}

.hamburger-close.hided .hamburger-bar, .hamburger-close.collapsed .hamburger-bar {
  opacity: 1;
}

.hamburger-close.hided:after, .hamburger-close.collapsed:after {
  transform: rotate(0);
}

.hamburger-arrow-left.collapsed {
  transform: rotate(180deg);
}

.hamburger-arrow-left.collapsed:before {
  width: .6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}

.hamburger-arrow-left.collapsed .hamburger-bar {
  border-radius: 0.2em;
}

.hamburger-arrow-left.collapsed:after {
  width: .6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}

.counter {
  text-align: center;
}

.counter > .counter-number,
.counter .counter-number-group {
  color: #424242;
  font-size: 20px;
}

.counter-label {
  display: block;
}

.counter-icon {
  font-size: 20px;
}

.counter-lg > .counter-number,
.counter-lg .counter-number-group {
  font-size: 40px;
}

.counter-lg .counter-icon {
  font-size: 40px;
}

.counter-md > .counter-number,
.counter-md .counter-number-group {
  font-size: 30px;
}

.counter-md .counter-icon {
  font-size: 30px;
}

.counter-sm > .counter-number,
.counter-sm .counter-number-group {
  font-size: 14px;
}

.counter-sm .counter-icon {
  font-size: 14px;
}

.counter-sm .counter-number-related + .counter-number,
.counter-sm .counter-number + .counter-number-related {
  margin-left: 0;
}

.counter-inverse {
  color: #ffffff;
}

.counter-inverse > .counter-number,
.counter-inverse .counter-number-group {
  color: #ffffff;
}

.counter-inverse .counter-icon {
  color: #ffffff;
}

.panel {
  position: relative;
  margin-bottom: 1.875rem;
  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 0.286rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-content > .row {
  padding-left: 30px;
  padding-right: 30px;
}

.panel-content > .row > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

.panel-heading {
  position: relative;
  padding: 0;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.214rem;
  border-top-right-radius: 0.214rem;
}

.panel-heading + .alert {
  border-radius: 0;
}

.panel-heading > .nav-tabs {
  border-bottom: none;
}

.panel-heading-tab {
  padding: 10px 30px 0;
  background-color: #3f51b5;
}

.panel-heading-tab > .nav-tabs .nav-link {
  color: #fff;
}

.panel-heading-tab > .nav-tabs .nav-link.hover, .panel-heading-tab > .nav-tabs .nav-link:hover {
  color: #757575;
}

.panel-heading-tab > .nav-tabs .nav-link.active, .panel-heading-tab > .nav-tabs .nav-link:active {
  color: #757575;
  background-color: #ffffff;
}

.panel-heading + .nav-tabs {
  margin-top: -0.715rem;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-body {
  position: relative;
  padding: 30px 30px;
}

.panel-body::after {
  display: block;
  clear: both;
  content: "";
}

.panel-heading + .panel-body {
  padding-top: 0;
}

.panel-body h1:first-child, .panel-body h2:first-child, .panel-body h3:first-child, .panel-body h4:first-child, .panel-body h5:first-child, .panel-body h6:first-child,
.panel-body .h1:first-child, .panel-body .h2:first-child, .panel-body .h3:first-child, .panel-body .h4:first-child, .panel-body .h5:first-child, .panel-body .h6:first-child {
  margin-top: 0;
}

.panel-body > *:last-child {
  margin-bottom: 0;
}

.panel-body > .list-group-dividered:only-child > .list-group-item:last-child {
  border-bottom-color: transparent;
}

.panel-footer {
  padding: 0 30px 15px;
  border-top: 1px solid transparent;
  background-color: transparent;
  border-bottom-right-radius: 0.214rem;
  border-bottom-left-radius: 0.214rem;
}

.table + .panel-footer {
  padding-top: 15px;
  border-color: #e0e0e0;
}

.panel-title {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 30px;
  font-size: 18px;
  color: #424242;
}

.panel-title > .icon {
  margin-right: 10px;
}

.panel-title > .badge {
  margin-left: 10px;
}

.panel-title small,
.panel-title .small {
  color: #757575;
}

.panel-title > a,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}

.panel-desc {
  display: block;
  padding: 5px 0 0;
  margin: 0;
  font-size: 1rem;
  color: #757575;
}

.panel-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0%, -50%);
  margin: auto;
  z-index: 1;
}

@media (max-width: 479px) {
  .panel-actions {
    right: 20px;
  }
}

ul .panel-actions {
  list-style: none;
}

ul .panel-actions > li {
  display: inline-block;
  margin-left: 8px;
}

ul .panel-actions > li:first-child {
  margin-left: 0;
}

.panel-actions a.dropdown-toggle {
  text-decoration: none;
}

.panel-actions .dropdown {
  display: inline-block;
}

.panel-actions .dropdown-toggle {
  display: inline-block;
}

.panel-actions .panel-action {
  display: inline-block;
  padding: 8px 10px;
  color: #9e9e9e;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}

.panel-actions .panel-action:hover {
  color: #616161;
}

.panel-actions .panel-action:active {
  color: #616161;
}

.panel-actions .panel-action[data-toggle="dropdown"]:not(.dropdown-toggle) {
  width: 34px;
  text-align: center;
}

.panel-actions .progress {
  width: 100px;
  margin: 0;
}

.panel-actions .pagination {
  margin: 0;
}

ul.panel-actions {
  list-style: none;
}

ul.panel-actions > li {
  display: inline-block;
  margin-left: 8px;
}

ul.panel-actions > li:first-child {
  margin-left: 0;
}

.panel-toolbar {
  padding: 5px 15px;
  margin: 0;
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.panel-bordered .panel-toolbar {
  border-top-color: transparent;
}

.panel-toolbar .btn {
  color: #9e9e9e;
  padding: 5px 10px;
}

.panel-toolbar .btn.icon {
  width: 1em;
  text-align: center;
}

.panel-toolbar .btn:hover, .panel-toolbar .btn:active, .panel-toolbar .btn.active {
  color: #757575;
}

.panel-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 0.286rem;
  opacity: .6;
}

.panel-loading .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panel > *:not(.panel-loading):not(.collapsing) {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.panel.is-loading > *:not(.panel-loading) {
  opacity: .3;
}

.panel.is-loading .panel-loading {
  display: block;
  opacity: 1;
}

.panel-footer-chart {
  padding: 0;
}

.panel-control {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.panel-body.scrollable-vertical {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 479px) {
  .panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.panel-body.scrollable-vertical > .scrollable-bar {
  margin-top: 0;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  transform: translateX(-26px);
}

.panel-bordered > .panel-body.scrollable-vertical > .scrollable-bar {
  margin-bottom: 30px;
  height: calc(100% - 60px);
}

.panel-body.scrollable-horizontal {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
  padding-top: 0;
  padding-bottom: 30px;
}

.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.panel-body.scrollable-horizontal > .scrollable-bar {
  margin-left: 0;
  margin-right: 30px;
  width: calc(100% - 60px);
  transform: translateY(-26px);
}

@media (max-width: 479px) {
  .panel-body.scrollable-horizontal > .scrollable-bar {
    margin-right: 20px;
    width: calc(100% - 40px);
  }
}

.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-bar {
  transform: translateY(-26px);
}

.panel-bordered > .panel-heading {
  border-bottom: 1px solid #e0e0e0;
}

.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 20px;
}

.panel-bordered > .panel-footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.panel-bordered > .panel-body {
  padding-top: 30px;
}

.panel-bordered > .table > tbody:first-child > tr:first-child th,
.panel-bordered > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel.is-dragging {
  opacity: 0.8;
}

.panel.is-dragging {
  cursor: move;
}

.panel > .nav-tabs-vertical .nav-tabs {
  margin-left: -1px;
}

.panel > .nav-tabs-vertical .nav-tabs > li > a {
  border-left: none;
  border-radius: 0;
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse {
  margin-right: -1px;
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse > li > a {
  border-right: none;
  border-radius: 0;
}

.panel:hover .panel-actions .show-on-hover {
  display: inline-block;
}

.panel .panel-actions .show-on-hover {
  display: none;
}

.panel.is-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.panel.is-fullscreen .panel-loading {
  border-radius: 0;
}

.panel.is-fullscreen .panel-actions [data-toggle=collapse] {
  display: none;
}

.panel.is-close {
  display: none;
}

.panel.is-collapse .panel-body {
  display: none;
  height: 0;
}

.panel > .alert {
  padding-left: 30px;
  padding-right: 30px;
}

.panel > .alert-dismissible {
  padding-right: 50px;
}

@media (max-width: 479px) {
  .panel > .alert {
    padding-left: 20px;
    padding-right: 20px;
  }
  .panel > .alert-dismissible {
    padding-right: 40px;
  }
}

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-left: 30px 30px;
  padding-right: 30px 30px;
}

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 0.214rem;
  border-top-right-radius: 0.214rem;
}

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 0.214rem;
  border-top-right-radius: 0.214rem;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 0.214rem;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 0.214rem;
}

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 0.214rem;
  border-bottom-left-radius: 0.214rem;
}

.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: 0.214rem;
  border-bottom-right-radius: 0.214rem;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 0.214rem;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 0.214rem;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #e0e0e0;
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel > .table > tr > td:first-child,
.panel > .table > tr > th:first-child, .panel > .table > thead > tr > td:first-child,
.panel > .table > thead > tr > th:first-child, .panel > .table > tbody > tr > td:first-child,
.panel > .table > tbody > tr > th:first-child, .panel > .table > tfoot > tr > td:first-child,
.panel > .table > tfoot > tr > th:first-child, .panel > .table-responsive .table > tr > td:first-child,
.panel > .table-responsive .table > tr > th:first-child, .panel > .table-responsive .table > thead > tr > td:first-child,
.panel > .table-responsive .table > thead > tr > th:first-child, .panel > .table-responsive .table > tbody > tr > td:first-child,
.panel > .table-responsive .table > tbody > tr > th:first-child, .panel > .table-responsive .table > tfoot > tr > td:first-child,
.panel > .table-responsive .table > tfoot > tr > th:first-child {
  padding-left: 30px;
}

@media (max-width: 479px) {
  .panel > .table > tr > td:first-child,
  .panel > .table > tr > th:first-child, .panel > .table > thead > tr > td:first-child,
  .panel > .table > thead > tr > th:first-child, .panel > .table > tbody > tr > td:first-child,
  .panel > .table > tbody > tr > th:first-child, .panel > .table > tfoot > tr > td:first-child,
  .panel > .table > tfoot > tr > th:first-child, .panel > .table-responsive .table > tr > td:first-child,
  .panel > .table-responsive .table > tr > th:first-child, .panel > .table-responsive .table > thead > tr > td:first-child,
  .panel > .table-responsive .table > thead > tr > th:first-child, .panel > .table-responsive .table > tbody > tr > td:first-child,
  .panel > .table-responsive .table > tbody > tr > th:first-child, .panel > .table-responsive .table > tfoot > tr > td:first-child,
  .panel > .table-responsive .table > tfoot > tr > th:first-child {
    padding-left: 20px;
  }
}

.panel > .table > tr > td:last-child,
.panel > .table > tr > th:last-child, .panel > .table > thead > tr > td:last-child,
.panel > .table > thead > tr > th:last-child, .panel > .table > tbody > tr > td:last-child,
.panel > .table > tbody > tr > th:last-child, .panel > .table > tfoot > tr > td:last-child,
.panel > .table > tfoot > tr > th:last-child, .panel > .table-responsive .table > tr > td:last-child,
.panel > .table-responsive .table > tr > th:last-child, .panel > .table-responsive .table > thead > tr > td:last-child,
.panel > .table-responsive .table > thead > tr > th:last-child, .panel > .table-responsive .table > tbody > tr > td:last-child,
.panel > .table-responsive .table > tbody > tr > th:last-child, .panel > .table-responsive .table > tfoot > tr > td:last-child,
.panel > .table-responsive .table > tfoot > tr > th:last-child {
  padding-right: 30px;
}

@media (max-width: 479px) {
  .panel > .table > tr > td:last-child,
  .panel > .table > tr > th:last-child, .panel > .table > thead > tr > td:last-child,
  .panel > .table > thead > tr > th:last-child, .panel > .table > tbody > tr > td:last-child,
  .panel > .table > tbody > tr > th:last-child, .panel > .table > tfoot > tr > td:last-child,
  .panel > .table > tfoot > tr > th:last-child, .panel > .table-responsive .table > tr > td:last-child,
  .panel > .table-responsive .table > tr > th:last-child, .panel > .table-responsive .table > thead > tr > td:last-child,
  .panel > .table-responsive .table > thead > tr > th:last-child, .panel > .table-responsive .table > tbody > tr > td:last-child,
  .panel > .table-responsive .table > tbody > tr > th:last-child, .panel > .table-responsive .table > tfoot > tr > td:last-child,
  .panel > .table-responsive .table > tfoot > tr > th:last-child {
    padding-right: 20px;
  }
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 1px solid #e0e0e0;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 0.214rem;
  border-top-right-radius: 0.214rem;
}

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 0.214rem;
  border-bottom-left-radius: 0.214rem;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel > .list-group .list-group-item {
  padding-right: 30px;
  padding-left: 30px;
}

@media (max-width: 479px) {
  .panel > .list-group .list-group-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group + .panel-footer {
  border-top-width: 0;
}

.panel-group {
  margin-bottom: 22px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0.286rem;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #e0e0e0;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #e0e0e0;
}

.panel.panel-transparent {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.panel.panel-transparent > .panel-heading, .panel.panel-transparent > .panel-footer {
  border-color: transparent;
}

.panel-default {
  border-color: #e0e0e0;
}

.panel-default > .panel-heading {
  color: #757575;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e0e0e0;
}

.panel-default > .panel-heading .badge-pill {
  color: #e0e0e0;
  background-color: #757575;
}

.panel-default .panel-title {
  color: #757575;
}

.panel-default .panel-action {
  color: #757575;
}

.panel-default .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e0e0e0;
}

.panel-default .panel-title {
  color: #424242;
}

.panel-primary {
  border-color: #3f51b5;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #3f51b5;
}

.panel-primary > .panel-heading .badge-pill {
  color: #3f51b5;
  background-color: #fff;
}

.panel-primary .panel-title {
  color: #fff;
}

.panel-primary .panel-action {
  color: #fff;
}

.panel-primary .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #3f51b5;
}

.panel-success {
  border-color: #509d44;
}

.panel-success > .panel-heading {
  color: #ffffff;
  background-color: #4caf50;
  border-color: #509d44;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #509d44;
}

.panel-success > .panel-heading .badge-pill {
  color: #4caf50;
  background-color: #ffffff;
}

.panel-success .panel-title {
  color: #ffffff;
}

.panel-success .panel-action {
  color: #ffffff;
}

.panel-success .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #509d44;
}

.panel-info {
  border-color: #00b0a7;
}

.panel-info > .panel-heading {
  color: #ffffff;
  background-color: #00bcd4;
  border-color: #00b0a7;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #00b0a7;
}

.panel-info > .panel-heading .badge-pill {
  color: #00bcd4;
  background-color: #ffffff;
}

.panel-info .panel-title {
  color: #ffffff;
}

.panel-info .panel-action {
  color: #ffffff;
}

.panel-info .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #00b0a7;
}

.panel-warning {
  border-color: #f06700;
}

.panel-warning > .panel-heading {
  color: #ffffff;
  background-color: #ff9800;
  border-color: #f06700;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f06700;
}

.panel-warning > .panel-heading .badge-pill {
  color: #ff9800;
  background-color: #ffffff;
}

.panel-warning .panel-title {
  color: #ffffff;
}

.panel-warning .panel-action {
  color: #ffffff;
}

.panel-warning .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f06700;
}

.panel-danger {
  border-color: #f3273c;
}

.panel-danger > .panel-heading {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f3273c;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f3273c;
}

.panel-danger > .panel-heading .badge-pill {
  color: #f44336;
  background-color: #ffffff;
}

.panel-danger .panel-title {
  color: #ffffff;
}

.panel-danger .panel-action {
  color: #ffffff;
}

.panel-danger .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f3273c;
}

.panel-dark {
  border-color: #616161;
}

.panel-dark > .panel-heading {
  color: #ffffff;
  background-color: #616161;
  border-color: #616161;
}

.panel-dark > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #616161;
}

.panel-dark > .panel-heading .badge-pill {
  color: #616161;
  background-color: #ffffff;
}

.panel-dark .panel-title {
  color: #ffffff;
}

.panel-dark .panel-action {
  color: #ffffff;
}

.panel-dark .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #616161;
}

.panel-primary,
.panel-info,
.panel-success,
.panel-warning,
.panel-danger,
.panel-dark {
  border: none;
}

.panel-primary .panel-heading,
.panel-info .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-danger .panel-heading,
.panel-dark .panel-heading {
  border: none;
}

.panel-primary .panel-title,
.panel-info .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title,
.panel-danger .panel-title,
.panel-dark .panel-title {
  color: #ffffff;
}

.panel-primary .panel-action,
.panel-info .panel-action,
.panel-success .panel-action,
.panel-warning .panel-action,
.panel-danger .panel-action,
.panel-dark .panel-action {
  color: #ffffff;
}

.panel-line .panel-heading {
  background: transparent;
  border: none;
  border-top: 3px solid transparent;
}

.panel-line.panel-default .panel-heading {
  color: #e0e0e0;
  border-top-color: #e0e0e0;
  background: transparent;
}

.panel-line.panel-default .panel-title {
  color: #e0e0e0;
}

.panel-line.panel-default .panel-action {
  color: #e0e0e0;
}

.panel-line.panel-default .panel-title {
  color: #424242;
}

.panel-line.panel-default .panel-action {
  color: #9e9e9e;
}

.panel-line.panel-primary .panel-heading {
  color: #3f51b5;
  border-top-color: #3f51b5;
  background: transparent;
}

.panel-line.panel-primary .panel-title {
  color: #3f51b5;
}

.panel-line.panel-primary .panel-action {
  color: #3f51b5;
}

.panel-line.panel-success .panel-heading {
  color: #4caf50;
  border-top-color: #4caf50;
  background: transparent;
}

.panel-line.panel-success .panel-title {
  color: #4caf50;
}

.panel-line.panel-success .panel-action {
  color: #4caf50;
}

.panel-line.panel-info .panel-heading {
  color: #00bcd4;
  border-top-color: #00bcd4;
  background: transparent;
}

.panel-line.panel-info .panel-title {
  color: #00bcd4;
}

.panel-line.panel-info .panel-action {
  color: #00bcd4;
}

.panel-line.panel-warning .panel-heading {
  color: #ff9800;
  border-top-color: #ff9800;
  background: transparent;
}

.panel-line.panel-warning .panel-title {
  color: #ff9800;
}

.panel-line.panel-warning .panel-action {
  color: #ff9800;
}

.panel-line.panel-danger .panel-heading {
  color: #f44336;
  border-top-color: #f44336;
  background: transparent;
}

.panel-line.panel-danger .panel-title {
  color: #f44336;
}

.panel-line.panel-danger .panel-action {
  color: #f44336;
}

.panel-line.panel-dark .panel-heading {
  color: #616161;
  border-top-color: #616161;
  background: transparent;
}

.panel-line.panel-dark .panel-title {
  color: #616161;
}

.panel-line.panel-dark .panel-action {
  color: #616161;
}

@media (max-width: 767px) {
  .panel-actions {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin: auto;
    padding: 0 30px 15px;
    transform: none;
  }
  .panel-actions-keep {
    position: absolute;
    top: 50%;
    right: 30px;
    padding: 0;
    transform: translate(0%, -50%);
  }
}

@media (max-width: 479px) {
  .panel-actions {
    padding-left: 20px;
  }
  .panel-actions-keep {
    right: 15px;
  }
  .panel-actions .progress {
    min-width: 80px;
  }
  .panel-actions .show-on-hover {
    display: none;
  }
  .panel-title, .panel-body, .panel-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.panel-group {
  margin-bottom: 22px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0.286rem;
  box-shadow: none;
}

.panel-group .panel + .panel {
  margin-top: 10px;
}

.panel-group .panel-title {
  position: relative;
  padding: 15px 45px 15px 30px;
  font-size: 1rem;
}

.panel-group .panel-title:before, .panel-group .panel-title:after {
  position: absolute;
  top: 15px;
  right: 30px;
  font-family: "Material Design Iconic";
  transition: all 300ms linear 0s;
}

.panel-group .panel-title:before {
  content: "";
}

.panel-group .panel-title:after {
  content: "";
}

.panel-group .panel-title[aria-expanded="false"]:before {
  opacity: .4;
}

.panel-group .panel-title[aria-expanded="false"]:after {
  opacity: 0;
  transform: rotate(-180deg);
}

.panel-group .panel-title[aria-expanded="true"]:before {
  opacity: 0;
  transform: rotate(180deg);
}

.panel-group .panel-title[aria-expanded="true"]:after {
  opacity: 1;
}

.panel-group .panel-title:hover, .panel-group .panel-title:focus {
  color: #757575;
  text-decoration: none;
}

.panel-group .panel-title:focus {
  outline: none;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse {
  margin: 0;
}

.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #e0e0e0;
}

.panel-group .panel-collapse .panel-body {
  padding: 15px 30px;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #e0e0e0;
}

.panel-group .panel + .panel {
  margin-top: 10px;
}

.panel-group-continuous .panel {
  border-radius: 0;
}

.panel-group-continuous .panel:first-child {
  border-radius: 0.286rem 0.286rem 0 0;
}

.panel-group-continuous .panel:last-child {
  border-radius: 0 0 0.286rem 0.286rem;
}

.panel-group-continuous .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #e0e0e0;
}

.panel-group-simple .panel {
  background: transparent;
  box-shadow: none;
}

.panel-group-simple .panel-title {
  padding-left: 0;
  padding-bottom: 10px;
}

.panel-group-simple .panel-title:before, .panel-group-simple .panel-title:after {
  right: 5px;
}

.panel-group-simple .panel-collapse .panel-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}

.panel-group-simple .panel + .panel {
  margin-top: 0;
}

.cover {
  overflow: hidden;
}

.cover-background {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cover-image {
  width: 100%;
}

.cover-quote {
  position: relative;
  margin-bottom: 0;
  padding-left: 35px;
  border-left: none;
}

.cover-quote:before, .cover-quote:after {
  position: absolute;
  top: -20px;
  font-size: 4em;
}

.cover-quote:before {
  left: 0;
  content: open-quote;
}

.cover-quote:after {
  right: 0;
  content: close-quote;
  visibility: hidden;
}

.cover-quote.blockquote-reverse {
  padding-right: 35px;
  padding-left: 20px;
  border-right: none;
}

.cover-quote.blockquote-reverse:before {
  right: 0;
  left: auto;
  content: close-quote;
}

.cover-gallery .carousel-inner img {
  width: 100%;
}

.cover-iframe {
  width: 100%;
  border: 0 none;
}

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.overlay {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.overlay-figure {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}

/* Sub-object `overlay-panel`
 ========================================================================== */
.overlay-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: #ffffff;
}

.overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

.overlay-panel > :last-child {
  margin-bottom: 0;
}

.overlay-panel h1, .overlay-panel h2, .overlay-panel h3, .overlay-panel h4, .overlay-panel h5, .overlay-panel h6 {
  color: inherit;
}

/* Sub-object `overlay-hover`
 ========================================================================== */
.overlay-hover:not(:hover) .overlay-panel:not(.overlay-background-fixed) {
  opacity: 0;
}

/* Modifier `overlay-background`
 ========================================================================== */
.overlay-background {
  background: rgba(0, 0, 0, 0.5);
}

/* Modifier `overlay-image`
 ========================================================================== */
.overlay-image {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

/* Modifier `overlay-shade`
 ========================================================================== */
.overlay-shade {
  background: transparent linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.87) 90%, #fff 100%) repeat scroll 0 0;
}

/* Position modifiers
 ========================================================================== */
.overlay-top {
  bottom: auto;
}

.overlay-bottom {
  top: auto;
}

.overlay-left {
  right: auto;
}

.overlay-right {
  left: auto;
}

/* Sub-object `overlay-icon`
 ========================================================================== */
.overlay-icon {
  font-size: 0;
  text-align: center;
}

.overlay-icon:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.overlay-icon .icon {
  display: inline-block;
  width: 2.286rem;
  height: 2.286rem;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 2.286rem;
  line-height: 1;
  color: #ffffff;
  text-decoration: none;
}

/* Sub-object `overlay-anchor`
 ========================================================================== */
.overlay-anchor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Transitions
 ========================================================================== */
.overlay-fade,
.overlay-scale,
.overlay-spin,
.overlay-grayscale,
.overlay-blur,
[class*='overlay-slide'] {
  transition-timing-function: ease-out;
  transition-duration: .3s;
  transition-property: opacity transform filter;
}

.overlay-fade {
  opacity: 0.7;
}

.overlay-hover:hover .overlay-fade {
  opacity: 1;
}

.overlay-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.overlay-hover:hover .overlay-scale {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

.overlay-hover:hover .overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.overlay-grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.overlay-hover:hover .overlay-grayscale {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

[class*='overlay-slide'] {
  opacity: 0;
}

/* Top */
.overlay-slide-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* Bottom */
.overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* Left */
.overlay-slide-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* Right */
.overlay-slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* Hover */
.overlay-hover:hover [class*='overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.comments {
  padding: 0;
  margin: 0;
}

.comments .comment {
  border: none;
  border-bottom: 1px solid #e0e0e0;
}

.comments .comment .comment:first-child {
  border-top: 1px solid #e0e0e0;
}

.comments .comment .comment:last-child {
  border-bottom: none;
}

.comment {
  padding: 20px 0;
  margin: 0;
}

.comment .comment {
  margin-top: 20px;
  padding-bottom: 20px;
}

.comment .comment:last-child {
  padding-bottom: 0;
}

.comment-author, .comment-author:hover, .comment-author:focus {
  color: #424242;
}

.comment-meta {
  margin-left: 5px;
  display: inline-block;
  font-size: 0.858rem;
  color: #9e9e9e;
}

.comment-content {
  margin-top: 5px;
}

.comment-content p:last-child {
  margin-bottom: 0;
}

.comment-actions {
  margin-top: 10px;
  text-align: right;
}

.comment-actions a {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.comment-actions a.icon {
  text-decoration: none;
}

.comment-actions a:last-child {
  margin-right: 0;
}

.comment-reply {
  margin: 22px 0 10px;
}

.comment-reply .form-group:last-child {
  margin-bottom: 0;
}

.chat-box {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}

.chats {
  padding: 30px 15px;
}

.chat-avatar {
  float: right;
}

.chat-avatar .avatar {
  width: 30px;
}

.chat-body {
  display: block;
  margin: 10px 30px 0 0;
  overflow: hidden;
}

.chat-body:first-child {
  margin-top: 0;
}

.chat-content {
  position: relative;
  display: block;
  float: right;
  padding: 8px 15px;
  margin: 0 20px 10px 0;
  clear: both;
  color: #ffffff;
  background-color: #3f51b5;
  border-radius: 0.286rem;
}

.chat-content p {
  margin-bottom: 0.5rem;
}

.chat-content:before {
  position: absolute;
  top: 10px;
  right: -10px;
  width: 0;
  height: 0;
  content: '';
  border: 5px solid transparent;
  border-left-color: #3f51b5;
}

.chat-content > p:last-child {
  margin-bottom: 0;
}

.chat-content + .chat-content:before {
  border-color: transparent;
}

.chat-left .chat-avatar {
  float: left;
}

.chat-left .chat-body {
  margin-right: 0;
  margin-left: 30px;
}

.chat-left .chat-content {
  float: left;
  margin: 0 0 10px 20px;
  color: #757575;
  background-color: #ebebeb;
}

.chat-left .chat-content:before {
  right: auto;
  left: -10px;
  border-right-color: #ebebeb;
  border-left-color: transparent;
}

.chat-left .chat-content + .chat-content:before {
  border-color: transparent;
}

.steps {
  margin: 0 0 22px;
  display: flex;
  flex-wrap: wrap;
}

.step {
  position: relative;
  padding: 12px 20px;
  margin: 0;
  color: #9e9e9e;
  font-size: inherit;
  vertical-align: top;
  background-color: #eeeeee;
  border-radius: 0;
}

.step-icon {
  float: left;
  font-size: 27px;
  margin-right: 0.5em;
}

.step-number {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  background: #e0e0e0;
  border-radius: 50%;
}

.step-number ~ .step-desc {
  min-height: 40px;
  margin-left: 50px;
}

.step-title {
  font-size: 20px;
  color: #616161;
  margin-bottom: 0;
}

.step-desc {
  text-align: left;
}

.step-desc p {
  margin-bottom: 0;
}

.steps-vertical {
  flex-direction: column;
}

.step.current, .step.active {
  color: #ffffff;
  background-color: #3f51b5;
}

.step.current .step-title, .step.active .step-title {
  color: #ffffff;
}

.step.current .step-number, .step.active .step-number {
  color: #3f51b5;
  background-color: #ffffff;
}

.step.disabled {
  color: #bdbdbd;
  pointer-events: none;
  cursor: auto;
}

.step.disabled .step-title {
  color: #bdbdbd;
}

.step.disabled .step-number {
  background-color: #bdbdbd;
}

.step.error {
  color: #ffffff;
  background-color: #f44336;
}

.step.error .step-title {
  color: #ffffff;
}

.step.error .step-number {
  color: #f44336;
  background-color: #ffffff;
}

.step.done {
  color: #ffffff;
  background-color: #4caf50;
}

.step.done .step-title {
  color: #ffffff;
}

.step.done .step-number {
  color: #4caf50;
  background-color: #ffffff;
}

.steps-lg .step {
  padding: 20px 20px;
  font-size: 16px;
}

.steps-lg .step-icon {
  font-size: 32px;
}

.steps-lg .step-title {
  font-size: 22px;
}

.steps-lg .step-number {
  width: 46px;
  height: 46px;
  font-size: 28px;
  line-height: 46px;
}

.steps-lg .step-number ~ .step-desc {
  min-height: 46px;
  margin-left: 56px;
}

.steps-sm .step {
  font-size: 12px;
}

.steps-sm .step-icon {
  font-size: 24px;
}

.steps-sm .step-title {
  font-size: 18px;
}

.steps-sm .step-number {
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
}

.steps-sm .step-number ~ .step-desc {
  min-height: 30px;
  margin-left: 40px;
}

.steps-xs .step {
  font-size: 10px;
}

.steps-xs .step-icon {
  font-size: 22px;
}

.steps-xs .step-title {
  font-size: 16px;
}

.steps-xs .step-number {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
}

.steps-xs .step-number ~ .step-desc {
  min-height: 24px;
  margin-left: 34px;
}

.pearls {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 22px;
}

.pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}

.pearl:before, .pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #eeeeee;
}

.pearl:before {
  left: 0;
}

.pearl:after {
  right: 0;
}

.pearl:first-child:before, .pearl:last-child:after {
  display: none !important;
}

.pearl-number, .pearl-icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  background: #bdbdbd;
  border-radius: 50%;
  border: 2px solid #bdbdbd;
}

.pearl-number {
  font-size: 18px;
}

.pearl-icon {
  font-size: 18px;
}

.pearl-title {
  margin-top: 0.5em;
  display: block;
  font-size: 16px;
  color: #616161;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pearl.current:before, .pearl.current:after, .pearl.active:before, .pearl.active:after {
  background-color: #3f51b5;
}

.pearl.current .pearl-number, .pearl.current .pearl-icon, .pearl.active .pearl-number, .pearl.active .pearl-icon {
  color: #3f51b5;
  background-color: #ffffff;
  border-color: #3f51b5;
  transform: scale(1.3);
}

.pearl.disabled {
  pointer-events: none;
  cursor: auto;
}

.pearl.disabled:before, .pearl.disabled:after {
  background-color: #eeeeee;
}

.pearl.disabled .pearl-number, .pearl.disabled .pearl-icon {
  color: #ffffff;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.pearl.error:before {
  background-color: #3f51b5;
}

.pearl.error:after {
  background-color: #eeeeee;
}

.pearl.error .pearl-number, .pearl.error .pearl-icon {
  color: #f44336;
  background-color: #ffffff;
  border-color: #f44336;
}

.pearl.done:before, .pearl.done:after {
  background-color: #3f51b5;
}

.pearl.done .pearl-number, .pearl.done .pearl-icon {
  color: #ffffff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.pearls-lg .pearl:before, .pearls-lg .pearl:after {
  top: 20px;
}

.pearls-lg .pearl-title {
  font-size: 18px;
}

.pearls-lg .pearl-number, .pearls-lg .pearl-icon {
  width: 40px;
  height: 40px;
  line-height: 36px;
}

.pearls-lg .pearl-icon {
  font-size: 20px;
}

.pearls-lg .pearl-number {
  font-size: 20px;
}

.pearls-sm .pearl:before, .pearls-sm .pearl:after {
  top: 16px;
}

.pearls-sm .pearl-title {
  font-size: 14px;
}

.pearls-sm .pearl-number, .pearls-sm .pearl-icon {
  width: 32px;
  height: 32px;
  line-height: 28px;
}

.pearls-sm .pearl-number {
  font-size: 16px;
}

.pearls-sm .pearl-icon {
  font-size: 14px;
}

.pearls-xs .pearl:before, .pearls-xs .pearl:after {
  top: 12px;
  height: 2px;
}

.pearls-xs .pearl-title {
  font-size: 12px;
}

.pearls-xs .pearl-number, .pearls-xs .pearl-icon {
  width: 24px;
  height: 24px;
  line-height: 20px;
}

.pearls-xs .pearl-number {
  font-size: 12px;
}

.pearls-xs .pearl-icon {
  font-size: 12px;
}

.timeline {
  position: relative;
  padding: 0;
  margin-bottom: 22px;
  list-style: none;
  background: transparent;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  margin-left: -1px;
  content: "";
  background-color: #e0e0e0;
}

.timeline::after {
  display: block;
  clear: both;
  content: "";
}

.timeline:not(.timeline-single) .timeline-item:first-child + .timeline-item,
.timeline:not(.timeline-single) .timeline-period + .timeline-item + .timeline-item {
  margin-top: 90px;
}

.timeline-item {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  padding-right: 40px;
  margin-bottom: 60px;
}

.timeline-item:not(.timeline-period)::after {
  display: block;
  clear: both;
  content: "";
}

.timeline-item.timeline-reverse {
  float: right;
  padding-right: 0;
  padding-left: 40px;
  clear: right;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-period {
  position: relative;
  z-index: 6;
  display: block;
  padding: 25px 10px;
  margin: 20px auto 30px;
  clear: both;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  background: #f1f4f5;
}

.timeline-content {
  width: 100%;
  overflow: hidden;
}

.timeline-dot {
  position: absolute;
  right: 0;
  z-index: 11;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #3f51b5;
  border-radius: 50%;
  top: 7.5px;
}

.timeline-reverse .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
}

.timeline .timeline-dot {
  width: 14px;
  height: 14px;
  margin-right: -7px;
  line-height: 14px;
}

.timeline .timeline-reverse .timeline-dot {
  margin-left: -7px;
}

.timeline.timeline-single {
  margin-left: 7px;
}

.timeline.timeline-single .timeline-dot {
  margin-left: -7px;
}

@media (max-width: 767px) {
  .timeline {
    margin-left: 7px;
  }
  .timeline .timeline-dot {
    margin-left: -7px;
  }
}

.timeline-info {
  float: right;
  padding: 0 20px;
  margin-bottom: 22px;
  line-height: 28px;
  text-align: center;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}

.timeline-reverse .timeline-info {
  float: left;
}

.timeline-footer {
  position: absolute;
  right: 0;
  bottom: -30px;
  margin-right: 55px;
}

.timeline-footer .icon {
  margin-right: .3em;
}

.timeline-reverse .timeline-footer {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: 55px;
}

.timeline-reverse + .timeline-reverse {
  margin-top: 0;
}

@media (max-width: 767px) {
  .timeline:before {
    left: 0;
  }
  .timeline-item, .timeline-item.timeline-reverse {
    float: none;
    width: 100%;
    padding-right: 0;
    padding-left: 40px;
    margin-top: 0;
    margin-bottom: 60px;
  }
  .timeline-dot {
    right: auto;
    left: 0;
    margin-right: 0;
    margin-left: -7px;
  }
  .timeline-info {
    display: inline-block;
    float: none;
  }
  .timeline-footer {
    right: auto;
    bottom: -26px;
    left: 0;
    margin-right: 0;
    margin-left: 40px;
  }
}

.timeline-single:before {
  left: 0;
}

.timeline-single .timeline-item {
  float: none;
  width: 100%;
  padding-right: 0;
  padding-left: 40px;
  margin-bottom: 60px;
}

.timeline-single .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: -7px;
}

.timeline-single .timeline-info {
  float: left;
}

.timeline-single .timeline-footer {
  right: auto;
  bottom: -26px;
  left: 0;
  margin-right: 0;
  margin-left: 40px;
}

.timeline-icon .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}

.timeline-icon .timeline-reverse .timeline-dot {
  margin-left: -20px;
}

.timeline-icon.timeline-single {
  margin-left: 20px;
}

.timeline-icon.timeline-single .timeline-dot {
  margin-left: -20px;
}

@media (max-width: 767px) {
  .timeline-icon {
    margin-left: 20px;
  }
  .timeline-icon .timeline-dot {
    margin-left: -20px;
  }
}

.timeline-icon .timeline-dot {
  top: -5.5px;
}

.timeline-avatar .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}

.timeline-avatar .timeline-reverse .timeline-dot {
  margin-left: -20px;
}

.timeline-avatar.timeline-single {
  margin-left: 20px;
}

.timeline-avatar.timeline-single .timeline-dot {
  margin-left: -20px;
}

@media (max-width: 767px) {
  .timeline-avatar {
    margin-left: 20px;
  }
  .timeline-avatar .timeline-dot {
    margin-left: -20px;
  }
}

.timeline-avatar-sm .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}

.timeline-avatar-sm .timeline-reverse .timeline-dot {
  margin-left: -15px;
}

.timeline-avatar-sm.timeline-single {
  margin-left: 15px;
}

.timeline-avatar-sm.timeline-single .timeline-dot {
  margin-left: -15px;
}

@media (max-width: 767px) {
  .timeline-avatar-sm {
    margin-left: 15px;
  }
  .timeline-avatar-sm .timeline-dot {
    margin-left: -15px;
  }
}

.timeline-avatar-lg .timeline-dot {
  width: 50px;
  height: 50px;
  margin-right: -25px;
  line-height: 50px;
}

.timeline-avatar-lg .timeline-reverse .timeline-dot {
  margin-left: -25px;
}

.timeline-avatar-lg.timeline-single {
  margin-left: 25px;
}

.timeline-avatar-lg.timeline-single .timeline-dot {
  margin-left: -25px;
}

@media (max-width: 767px) {
  .timeline-avatar-lg {
    margin-left: 25px;
  }
  .timeline-avatar-lg .timeline-dot {
    margin-left: -25px;
  }
}

.timeline-simple .timeline-dot {
  top: 0;
  margin-top: 10px;
}

.timeline-feed .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}

.timeline-feed .timeline-reverse .timeline-dot {
  margin-left: -15px;
}

.timeline-feed.timeline-single {
  margin-left: 15px;
}

.timeline-feed.timeline-single .timeline-dot {
  margin-left: -15px;
}

@media (max-width: 767px) {
  .timeline-feed {
    margin-left: 15px;
  }
  .timeline-feed .timeline-dot {
    margin-left: -15px;
  }
}

.timeline-feed.timeline-simple .timeline-dot {
  margin-top: 5px;
}

.timeline-feed .timeline-item {
  padding-right: 30px;
  margin-bottom: 22px;
}

.timeline-feed .timeline-item.timeline-reverse {
  padding-left: 30px;
}

.timeline-feed.timeline-single .timeline-item {
  padding-left: 30px;
}

@media (max-width: 767px) {
  .timeline-feed .timeline-item {
    padding-right: 30px;
    margin-bottom: 22px;
  }
}

.testimonial {
  margin: 3px 3px 22px;
}

.testimonial-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.testimonial-item {
  float: left;
  padding: 0 15px 30px;
  margin: 0;
}

.testimonial-content {
  position: relative;
  padding: 15px 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  background-color: #eeeeee;
  border-radius: 0.215rem;
}

.testimonial-content:before {
  position: absolute;
  bottom: -7px;
  left: 33px;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background-color: #eeeeee;
  transform: rotate(45deg);
}

.testimonial-content > p:last-child {
  margin-bottom: 0;
}

.testimonial-image {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}

.testimonial-author {
  display: block;
  margin-left: 75px;
  font-size: 18px;
}

.testimonial-company {
  display: block;
  margin-left: 75px;
  font-size: 0.858rem;
  opacity: .8;
}

.testimonial-control a {
  color: #bdbdbd;
}

.testimonial-control a:hover {
  color: #3f51b5;
  text-decoration: none;
}

.testimonial-reverse .testimonial-content:before {
  right: 33px;
  left: auto;
}

.testimonial-reverse .testimonial-image {
  float: right;
  margin-right: 20px;
  margin-left: 0;
}

.testimonial-reverse .testimonial-author,
.testimonial-reverse .testimonial-company {
  margin-left: 0;
  margin-right: 75px;
  text-align: right;
}

.testimonial-top .testimonial-item {
  padding: 30px 15px 0;
}

.testimonial-top .testimonial-content {
  margin-top: 30px;
  margin-bottom: 10px;
}

.testimonial-top .testimonial-content:before {
  top: -7px;
  bottom: auto;
}

.testimonial.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.testimonial.carousel .testimonial-item {
  position: relative;
  display: none;
  width: 100%;
  padding: 10px;
  transition: left 0.6s ease-in-out 0s;
}

.testimonial.carousel .testimonial-item.active, .testimonial.carousel .testimonial-item.next, .testimonial.carousel .testimonial-item.prev {
  display: block;
}

.testimonial.carousel .testimonial-item.next, .testimonial.carousel .testimonial-item.prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.testimonial.carousel .testimonial-item.next {
  left: 100%;
}

.testimonial.carousel .testimonial-item.prev {
  left: -100%;
}

.testimonial.carousel .testimonial-item.next.left, .testimonial.carousel .testimonial-item.prev.right {
  left: 0;
}

.testimonial.carousel .testimonial-item.active {
  left: 0;
}

.testimonial.carousel .testimonial-item.active.left {
  left: -100%;
}

.testimonial.carousel .testimonial-item.active.right {
  left: 100%;
}

.testimonial.carousel .testimonial-content {
  padding: 10px;
}

.testimonial.carousel .testimonial-control {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.testimonial.carousel .testimonial-control > * {
  margin-left: 10px;
}

.testimonial.carousel.testimonial-reverse .testimonial-control {
  right: auto;
  left: 10px;
}

.testimonial.carousel.testimonial-reverse .testimonial-control > * {
  margin-right: 10px;
  margin-left: 0;
}

.testimonial.carousel.testimonial-top .testimonial-control {
  top: 20px;
  bottom: auto;
}

.pricing-list {
  margin-bottom: 22px;
  text-align: center;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.215rem;
}

.pricing-list [class^="bg-"],
.pricing-list [class^="bg-"] *,
.pricing-list [class*="bg-"],
.pricing-list [class*="bg-"] * {
  color: #ffffff;
}

.pricing-list .pricing-header {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0.215rem 0.215rem 0 0;
}

.pricing-list .pricing-title {
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 0.215rem 0.215rem 0 0;
}

.pricing-list .pricing-price {
  padding: 20px 30px;
  margin: 0;
  font-size: 3.858rem;
  font-weight: 500;
  color: #424242;
}

.pricing-list .pricing-period {
  font-size: 1rem;
  font-weight: 300;
}

.pricing-list .pricing-features {
  padding: 0 18px;
  margin: 0;
}

.pricing-list .pricing-features li {
  display: block;
  padding: 15px;
  list-style: none;
  border-top: 1px dashed #e0e0e0;
}

.pricing-list .pricing-features li:first-child {
  border-top: none;
}

.pricing-list .pricing-footer {
  padding: 30px;
  border-radius: 0 0 0.215rem 0.215rem;
}

.pricing-table {
  padding-top: 30px;
  text-align: center;
}

.pricing-table::after {
  display: block;
  clear: both;
  content: "";
}

.pricing-table [class*="pricing-column"] {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
  border-right: none;
}

.pricing-table [class*="pricing-column"]:last-child {
  border-right: 1px solid #e0e0e0;
}

.pricing-table [class*="pricing-column"].featured {
  position: relative;
  margin-right: -1px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
}

.pricing-table .pricing-header {
  padding-bottom: 24px;
  margin: 30px 30px 25px;
  border-bottom: 1px solid #e0e0e0;
}

.pricing-table .pricing-price {
  font-size: 48px;
}

.pricing-table .pricing-currency {
  display: inline-block;
  margin-top: 10px;
  margin-right: -10px;
  font-size: 20px;
  vertical-align: top;
}

.pricing-table .pricing-period {
  font-size: 16px;
}

.pricing-table .pricing-title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.pricing-table .pricing-features {
  padding: 0;
  margin: 0;
}

.pricing-table .pricing-features li {
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
  list-style: none;
}

.pricing-table .pricing-footer {
  padding: 20px 0;
  margin: 25px 30px 30px;
}

@media (min-width: 768px) {
  .pricing-table .pricing-column-three {
    width: 33.33%;
  }
  .pricing-table .pricing-column-three.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
  .pricing-table .pricing-column-four {
    width: 50%;
  }
  .pricing-table .pricing-column-five {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .pricing-table .pricing-column-four {
    width: 25%;
  }
  .pricing-table .pricing-column-five {
    width: 20%;
  }
  .pricing-table .pricing-column-four.featured, .pricing-table .pricing-column-five.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
}

.rating {
  display: inline-block;
  margin: 0 .5rem 0 0;
  font-size: 0;
  vertical-align: middle;
}

.rating:before {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: '';
}

.rating.hover .icon.active {
  opacity: .5;
}

.rating .icon {
  width: 1em;
  height: auto;
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 1rem;
  color: #bdbdbd;
  vertical-align: middle;
  cursor: pointer;
}

.rating .icon:before {
  transition: color .3s ease, opacity .3s ease;
}

.rating .icon.active {
  color: #fb8c00 !important;
}

.rating .icon.active.hover {
  color: #fb8c00 !important;
  opacity: 1;
}

.rating .icon.hover {
  color: #fb8c00 !important;
  opacity: 1;
}

.rating .icon:last-child {
  margin-right: 0;
}

.rating-disabled .icon {
  cursor: default;
}

.rating-sm .icon {
  font-size: 0.858rem;
}

.rating-lg .icon {
  font-size: 1.286rem;
}

.ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 150px;
  height: 150px;
  text-align: center;
  background-color: transparent;
}

.ribbon-inner {
  position: absolute;
  top: 16px;
  left: 0;
  display: inline-block;
  height: 30px;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #616161;
}

.ribbon-inner .icon {
  font-size: 16px;
}

.ribbon-lg .ribbon-inner {
  height: 38px;
  font-size: 1.286rem;
  line-height: 38px;
}

.ribbon-sm .ribbon-inner {
  height: 26px;
  font-size: 0.858rem;
  line-height: 26px;
}

.ribbon-xs .ribbon-inner {
  height: 22px;
  font-size: 0.858rem;
  line-height: 22px;
}

.ribbon-vertical .ribbon-inner {
  top: 0;
  left: 16px;
  width: 30px;
  height: 60px;
  padding: 15px 0;
}

.ribbon-vertical.ribbon-xs .ribbon-inner {
  width: 22px;
  height: 50px;
}

.ribbon-vertical.ribbon-sm .ribbon-inner {
  width: 26px;
  height: 55px;
}

.ribbon-vertical.ribbon-lg .ribbon-inner {
  width: 38px;
  height: 70px;
}

.ribbon-reverse {
  right: -3px;
  left: auto;
}

.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}

.ribbon-reverse.ribbon-vertical .ribbon-inner {
  right: 16px;
}

.ribbon-bookmark .ribbon-inner {
  padding-right: 42px;
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #616161 0);
  box-shadow: none;
}

.ribbon-bookmark .ribbon-inner:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #616161;
  border-right: 10px solid transparent;
}

.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  height: 82px;
  padding-right: 0;
  padding-bottom: 37px;
  background-image: linear-gradient(to top, transparent 22px, #616161 0);
}

.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  top: auto;
  bottom: 0;
  left: 0;
  margin-top: -15px;
  border-right: 15px solid #616161;
  border-bottom: 10px solid transparent;
}

.ribbon-bookmark.ribbon-vertical.ribbon-xs .ribbon-inner:before {
  margin-top: -11px;
}

.ribbon-bookmark.ribbon-vertical.ribbon-sm .ribbon-inner:before {
  margin-top: -13px;
}

.ribbon-bookmark.ribbon-vertical.ribbon-lg .ribbon-inner:before {
  margin-top: -19px;
}

.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  padding-left: 42px;
  padding-right: 20px;
  background-image: linear-gradient(to right, transparent 22px, #616161 0);
}

.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  left: 0;
  border-right: 15px solid #616161;
  border-left: 10px solid transparent;
}

.ribbon-bookmark.ribbon-reverse.ribbon-vertical .ribbon-inner {
  padding-left: 0;
  padding-right: 0;
}

.ribbon-bookmark.ribbon-reverse.ribbon-vertical .ribbon-inner:before {
  right: auto;
  left: 0;
  border-right-color: #616161;
  border-bottom-color: transparent;
  border-left: 15px solid #616161;
}

.ribbon-bookmark.ribbon-xs .ribbon-inner:before {
  border-width: 11px;
}

.ribbon-bookmark.ribbon-sm .ribbon-inner:before {
  border-width: 13px;
}

.ribbon-bookmark.ribbon-lg .ribbon-inner:before {
  border-width: 19px;
}

.ribbon-badge {
  top: -2px;
  left: -2px;
  overflow: hidden;
}

.ribbon-badge .ribbon-inner {
  left: -40px;
  width: 100%;
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-reverse {
  right: -2px;
  left: auto;
}

.ribbon-badge.ribbon-reverse .ribbon-inner {
  right: -40px;
  left: auto;
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: -2px;
}

.ribbon-badge.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom.ribbon-reverse .ribbon-inner {
  transform: rotate(-45deg);
}

.ribbon-corner {
  top: 0;
  left: 0;
  overflow: hidden;
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #616161;
  border-left-color: #616161;
}

.ribbon-corner .ribbon-inner {
  top: 0;
  left: 0;
  width: 40px;
  height: 35px;
  padding: 0;
  line-height: 35px;
  background-color: transparent;
}

.ribbon-corner.ribbon-reverse {
  right: 0;
  left: auto;
}

.ribbon-corner.ribbon-reverse:before {
  right: 0;
  left: auto;
  border-right-color: #616161;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}

.ribbon-corner.ribbon-bottom {
  top: auto;
  bottom: 0;
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #616161;
}

.ribbon-corner.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 0;
}

.ribbon-corner.ribbon-xs:before {
  border-width: 22px;
}

.ribbon-corner.ribbon-xs .ribbon-inner {
  width: 28px;
  height: 26px;
  line-height: 26px;
}

.ribbon-corner.ribbon-xs .ribbon-inner > .icon {
  font-size: 0.858rem;
}

.ribbon-corner.ribbon-sm:before {
  border-width: 26px;
}

.ribbon-corner.ribbon-sm .ribbon-inner {
  width: 34px;
  height: 32px;
  line-height: 32px;
}

.ribbon-corner.ribbon-sm .ribbon-inner > .icon {
  font-size: 0.858rem;
}

.ribbon-corner.ribbon-lg:before {
  border-width: 36px;
}

.ribbon-corner.ribbon-lg .ribbon-inner {
  width: 46px;
  height: 44px;
  line-height: 44px;
}

.ribbon-corner.ribbon-lg .ribbon-inner > .icon {
  font-size: 1.286rem;
}

.ribbon-clip {
  left: -14px;
}

.ribbon-clip:before {
  position: absolute;
  top: 46px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 7px solid transparent;
  border-top-color: #424242;
  border-right-color: #424242;
}

.ribbon-clip .ribbon-inner {
  padding-left: 23px;
  border-radius: 0 5px 5px 0;
}

.ribbon-clip.ribbon-reverse {
  right: -14px;
  left: auto;
}

.ribbon-clip.ribbon-reverse:before {
  right: 0;
  left: auto;
  border-right-color: transparent;
  border-left-color: #424242;
}

.ribbon-clip.ribbon-reverse .ribbon-inner {
  padding-right: 23px;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
}

.ribbon-clip.ribbon-bottom {
  top: auto;
  bottom: -3px;
}

.ribbon-clip.ribbon-bottom:before {
  top: auto;
  bottom: 46px;
  border-top-color: transparent;
  border-bottom-color: #424242;
}

.ribbon-clip.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
}

.ribbon-clip.ribbon-xs:before {
  top: 38px;
}

.ribbon-clip.ribbon-xs.ribbon-bottom:before {
  top: auto;
  bottom: 38px;
}

.ribbon-clip.ribbon-sm:before {
  top: 42px;
}

.ribbon-clip.ribbon-sm.ribbon-bottom:before {
  top: auto;
  bottom: 42px;
}

.ribbon-clip.ribbon-lg:before {
  top: 54px;
}

.ribbon-clip.ribbon-lg.ribbon-bottom:before {
  top: auto;
  bottom: 54px;
}

.ribbon-primary .ribbon-inner {
  background-color: #3f51b5;
}

.ribbon-primary.ribbon-bookmark .ribbon-inner {
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #3f51b5 0);
}

.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
  border-color: #3f51b5;
  border-right-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  background-image: linear-gradient(to right, transparent 22px, #3f51b5 0);
}

.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  background-image: linear-gradient(to top, transparent 22px, #3f51b5 0);
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-bottom-color: transparent;
  border-left-color: #3f51b5;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #3f51b5;
  border-left-color: #3f51b5;
}

.ribbon-primary.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-reverse:before {
  border-right-color: #3f51b5;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #3f51b5;
}

.ribbon-primary.ribbon-clip:before {
  border-top-color: #3949ab;
  border-right-color: #3949ab;
}

.ribbon-primary.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: #3949ab;
}

.ribbon-primary.ribbon-clip.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #3949ab;
}

.ribbon-success .ribbon-inner {
  background-color: #4caf50;
}

.ribbon-success.ribbon-bookmark .ribbon-inner {
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #4caf50 0);
}

.ribbon-success.ribbon-bookmark .ribbon-inner:before {
  border-color: #4caf50;
  border-right-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  background-image: linear-gradient(to right, transparent 22px, #4caf50 0);
}

.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #4caf50;
  border-left-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  background-image: linear-gradient(to top, transparent 22px, #4caf50 0);
}

.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #4caf50;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #4caf50;
  border-bottom-color: transparent;
  border-left-color: #4caf50;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #4caf50;
  border-left-color: #4caf50;
}

.ribbon-success.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-reverse:before {
  border-right-color: #4caf50;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #4caf50;
}

.ribbon-success.ribbon-clip:before {
  border-top-color: #43a047;
  border-right-color: #43a047;
}

.ribbon-success.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: #43a047;
}

.ribbon-success.ribbon-clip.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #43a047;
}

.ribbon-info .ribbon-inner {
  background-color: #00bcd4;
}

.ribbon-info.ribbon-bookmark .ribbon-inner {
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #00bcd4 0);
}

.ribbon-info.ribbon-bookmark .ribbon-inner:before {
  border-color: #00bcd4;
  border-right-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  background-image: linear-gradient(to right, transparent 22px, #00bcd4 0);
}

.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  background-image: linear-gradient(to top, transparent 22px, #00bcd4 0);
}

.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
  border-left-color: #00bcd4;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #00bcd4;
  border-left-color: #00bcd4;
}

.ribbon-info.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-reverse:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #00bcd4;
}

.ribbon-info.ribbon-clip:before {
  border-top-color: #00acc1;
  border-right-color: #00acc1;
}

.ribbon-info.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: #00acc1;
}

.ribbon-info.ribbon-clip.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #00acc1;
}

.ribbon-warning .ribbon-inner {
  background-color: #ff9800;
}

.ribbon-warning.ribbon-bookmark .ribbon-inner {
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #ff9800 0);
}

.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
  border-color: #ff9800;
  border-right-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  background-image: linear-gradient(to right, transparent 22px, #ff9800 0);
}

.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #ff9800;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  background-image: linear-gradient(to top, transparent 22px, #ff9800 0);
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #ff9800;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #ff9800;
  border-bottom-color: transparent;
  border-left-color: #ff9800;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #ff9800;
  border-left-color: #ff9800;
}

.ribbon-warning.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-reverse:before {
  border-right-color: #ff9800;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ff9800;
}

.ribbon-warning.ribbon-clip:before {
  border-top-color: #fb8c00;
  border-right-color: #fb8c00;
}

.ribbon-warning.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: #fb8c00;
}

.ribbon-warning.ribbon-clip.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fb8c00;
}

.ribbon-danger .ribbon-inner {
  background-color: #f44336;
}

.ribbon-danger.ribbon-bookmark .ribbon-inner {
  background-color: transparent;
  background-image: linear-gradient(to left, transparent 22px, #f44336 0);
}

.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
  border-color: #f44336;
  border-right-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner {
  background-image: linear-gradient(to right, transparent 22px, #f44336 0);
}

.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f44336;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner {
  background-image: linear-gradient(to top, transparent 22px, #f44336 0);
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #f44336;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f44336;
  border-bottom-color: transparent;
  border-left-color: #f44336;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #f44336;
  border-left-color: #f44336;
}

.ribbon-danger.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-reverse:before {
  border-right-color: #f44336;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f44336;
}

.ribbon-danger.ribbon-clip:before {
  border-top-color: #e53935;
  border-right-color: #e53935;
}

.ribbon-danger.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: #e53935;
}

.ribbon-danger.ribbon-clip.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e53935;
}

.color-selector {
  padding: 0;
  margin: 0;
  list-style: none;
}

.color-selector > li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 8px 8px 0;
  background-color: #3f51b5;
  border-radius: 100%;
}

.color-selector > li:hover {
  opacity: .8;
}

.color-selector > li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: inherit;
  height: inherit;
  content: "";
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;
}

.color-selector > li input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  cursor: pointer;
  border-radius: inherit;
  opacity: 0;
}

.color-selector > li input[type="radio"]:disabled {
  cursor: not-allowed;
}

.color-selector > li label {
  position: relative;
  font-family: "Material Design Iconic";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}

.color-selector > li input[type="radio"]:checked + label:after {
  position: absolute;
  top: 0;
  left: 8px;
  display: inline-block;
  margin-top: -2px;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  content: "";
}

.color-selector > li.color-selector-disabled {
  background-color: #bdbdbd !important;
}

.color-selector > li.color-selector-disabled input[type="radio"]:disabled {
  cursor: not-allowed;
}

.example-wrap {
  margin-bottom: 80px;
}

.example-wrap .example-wrap {
  margin-bottom: 0;
}

.example {
  margin-top: 20px;
  margin-bottom: 20px;
}

.example:before, .example:after {
  content: "";
  display: table;
}

.example::after {
  display: block;
  clear: both;
  content: "";
}

.example-title {
  text-transform: uppercase;
}

h4.example-title {
  font-size: 14px;
}

h3.example-title {
  font-size: 18px;
}

.panel-body > .example-wrap:last-child {
  margin-bottom: 0;
}

.panel-body > .row:last-child > [class*="col-"]:last-child .example-wrap:last-child {
  margin-bottom: 0;
}

.example-well {
  position: relative;
  margin-bottom: 30px;
  background-color: #f1f4f5;
}

.example-well .center {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.example-dropdown .dropdown::after,
.example-dropdown .dropup::after {
  display: block;
  clear: both;
  content: "";
}

.example-dropdown .dropdown > .dropdown-toggle,
.example-dropdown .dropup > .dropdown-toggle {
  float: left;
}

.example-dropdown .dropdown > .dropdown-menu,
.example-dropdown .dropup > .dropdown-menu {
  position: static;
  display: block;
  clear: left;
}

.example-dropdown .dropdown > .dropdown-menu-right,
.example-dropdown .dropup > .dropdown-menu-right {
  float: right;
  clear: right;
}

.example-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.example-tooltip .tooltip {
  position: relative;
  margin-right: 25px;
  opacity: 1;
}

.example-grid .example-col, .example-blocks .example-col {
  min-height: 0;
  padding: 10px 15px 12px;
  background-color: #f1f4f5;
  border-radius: 0;
}

.example-grid .example-col {
  margin-bottom: 20px;
}

.example-grid .example-col .example-col {
  margin-top: 20px;
  margin-bottom: 0;
  background-color: #e2e8ea;
}

.example-popover {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.example-popover .popover {
  position: relative;
  display: block;
  margin-right: 25px;
}

.example-buttons .btn,
.example-buttons .btn-group,
.example-buttons .btn-group-vertical {
  margin-right: 15px;
  margin-bottom: 20px;
}

.example-buttons .btn-group-vertical .btn,
.example-buttons .btn-group-vertical .btn-group,
.example-buttons .btn-group-vertical .btn-group-vertical,
.example-buttons .btn-group .btn,
.example-buttons .btn-group .btn-group,
.example-buttons .btn-group .btn-group-vertical {
  margin-right: 0;
  margin-bottom: 0;
}

.example-box {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 45px 15px 15px;
  border: 1px solid #e0e0e0;
}

.example-box:after {
  position: absolute;
  top: 15px;
  left: 15px;
  content: "Example";
  color: #959595;
  font-size: 0.858rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.example-avatars .avatar {
  margin-right: 20px;
  margin-bottom: 20px;
}

.example-avatars .avatar:last-child {
  margin-right: 20px;
}

.example-typography {
  position: relative;
  padding-left: 25%;
}

.example-typography .heading-note,
.example-typography .text-note {
  position: absolute;
  bottom: 2px;
  left: 0;
  display: block;
  width: 260px;
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  color: #AAB2BD;
}

.example-typography .text-note {
  top: 10px;
  bottom: auto;
}

.example-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media (max-width: 767px) {
  .example-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
